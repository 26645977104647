import { Typography } from "@mui/material";
import React from "react";
import styled from "styled-components";
import Cancelled from './cancel.svg'
type CancelOrderProps = {
  title: string;
  description: string;
};

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  background-color: #f8f8f8;
  padding: 16px;
  border-radius: 8px;
`;

const Icon = styled.img`
  width: 24px;
  height: 24px;
`;

const CancelOrder: React.FC<CancelOrderProps> = ({
  title,
  description,
}) => {
  return (
    <Wrapper>
      <div style={{display:'flex', alignItems:'center', gap:'10px'}}>
        <Icon src={Cancelled} alt="icon" />
        <Typography sx={{fontSize:'34px'}} variant='h5'>{title}</Typography>
      </div>
      <Typography variant='body1'>{description}</Typography>

    </Wrapper>
  );
};

export default CancelOrder;
