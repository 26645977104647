export const toFormData = (data:any) => {
  const formData = new FormData();

  for (const key in data) {
    if (key === 'images') {
      console.log('add to fromdata', data[key]);
      for (const key in data['images']) {
        formData.append('images', data['images'][key]);
      }
    } else {
      console.log('add to fromdata', key);
      formData.append(key, data[key]);
      console.log(formData);
    }
  }
  return formData;
};
