import React, { FC, useState } from "react";
import { Input } from "../../components";
import { icons } from "../../icons";
import {
  ExchangeContainer,
  TitleWrapper,
  Title,
  Variables,
  VariableItem,
  BodyWrapper,
  BodyItem,
  ItemImage,
  ItemText,
  InputWrapper,
  ItemImageWrapper,
  SoonText,
  ItemImageSelect
} from './exchange.styles'
// import Select, { SelectChangeEvent } from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import handleImageUrl from "../../hooks/useImageUrl";
import { ReactSVG } from "react-svg";
import { PlaceHoldeImage } from "./styles";
import { useCrypto } from "../../context/crypto.context";
import Tooltip from '@mui/material/Tooltip';
import { useTranslation } from "react-i18next";
import Select from 'react-select';
import styled from "styled-components";
import './index.css'

const StyledSelect = styled(Select)`
  width:100%;
  min-height: 100% !important;
  /* flex-grow:1; */
  color:#FFF!important;
  flex:auto 1;
  @media screen and (max-width: 700px){
    height: 40px;
    flex: none;
    /* width:50%; */
  }
`

type ExchangeProps = {
  title:string;
  variables:any[];
  bodyItems:any[];
  formikProps:any;
  fieldName:string;
  currency:any;
  setCurrency:any;
  blockField?:any;
}
const ExchangeForm:FC<ExchangeProps> = (props) => {
  const [t] = useTranslation()
  const {
    title,
    variables,
    bodyItems,
    formikProps,
    fieldName,
    currency,
    setCurrency,
    blockField=false,
  } = props

  const handleChange = (event:any) => {
    setCurrency(event);
  };
  const formatOptionLabel = ({ value, label, icon, isBlocked }:{ value:any, label:any, icon:any, isBlocked:any }) => (
    <BodyItem isBlocked={isBlocked} style={{flexDirection:'row'}} key={value}>
      {icon.indexOf('svg') !== -1 ?
        <ReactSVG src={handleImageUrl(icon)} style={{width: '25px', height:'25px'}}  />
      :
        <ItemImageSelect src={handleImageUrl(icon)}/>
      }
      <ItemText style={currency?._id === value ? {color:"#FFF"} : {}}>
        {label||''}
      </ItemText>
    </BodyItem>
  );

  return (
    <ExchangeContainer>
      <TitleWrapper>
        <Title>
          {title}:
        </Title>
      </TitleWrapper>
      <BodyWrapper>
        {
          bodyItems.map((item) => (
            <div style={{position:'relative', display:'flex', justifyContent:'center', boxSizing:'border-box'}}>
              {item?.isBlocked ? 
                <Tooltip title={`${t("CURRENCY_SOON_TEXT")}`}>
                  <SoonText>
                    {t("SOON")}
                  </SoonText>
                </Tooltip>
                : null  
              }
              <BodyItem isBlocked={item?.isBlocked} onClick={item?.isBlocked ? () => {} : () => setCurrency(item)} key={item._id}>
                {item?.icon?.indexOf('svg') !== -1 ?
                  <ReactSVG src={handleImageUrl(item.icon)} style={{width: '50px', height:'50px'}}  />
                :
                  <ItemImage active={currency?._id === item._id} src={handleImageUrl(item.icon)}/>
                }
                  <ItemText>
                    {item.name}
                  </ItemText>
              </BodyItem>
            </div>
          ))
        }
      </BodyWrapper>
      <InputWrapper>
        <div style={{
          display:'flex',
          alignItems:'center',
          position:'relative',
          width:'100%',
          flex:'1 auto'
        }}>
          {/* {(blockField ? currency2 : currency1) && 
          <PlaceHoldeImage 
            src={handleImageUrl(blockField ? currency2?.icon : currency1?.icon)}
          />} */}
          <Input
            customStyles={{color:'#FFF', flex:'1 auto'}}
            // customStyles={(blockField ? currency2 : currency1) ? {color:'#000', flex:'1 auto',paddingLeft:'60px'} :  {color:'#000', flex:'1 auto'}}
            fieldName={fieldName}
            isExchangeField
            isBlocked={blockField}
            {...formikProps}
            
          />
          </div>
          <StyledSelect
            styles={{
              control: (provided, state) => ({
                ...provided,
                'min-height': '64px',
                borderRadius:'16px',
                backgroundColor:'transparent',
                borderColor:'#888888',
                color:'#FFF',
                // width: '70%',
              }),
              input: provided => ({
                ...provided,
                color: '#FFF'
              }),

              singleValue: provided => ({
                ...provided,
                color: '#FFF'
              })
            }}
            defaultValue={bodyItems[0]}
            isDisabled={bodyItems?.length === 0}
            // isLoading={isLoading}
            placeholder={`${t("SELECT")}`}
            isClearable={true}
            isSearchable={true}
            //@ts-ignore
            formatOptionLabel={formatOptionLabel}
            options={bodyItems}
            value={currency}
            // value={currency ? currency : blockField ? bodyItems[0] : bodyItems[1]}
            onChange={(item:any) => item?.isBlocked ? () => {} : handleChange(item)}
          />
        {/* </div> */}
      </InputWrapper>
    </ExchangeContainer>
  );
}

export default ExchangeForm;