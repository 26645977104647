import { FC, useRef, useEffect, useState } from "react";
import {
  ArticleCard,
  ArticlesImg,
  TitleWrapper,
  ContentWrapper,
  ArticleTitle,
  ArticleSubTitle,
  DateWrapper,
  DateIcon,
} from './styles'
import { Typography } from "@mui/material";
import CalendarIcon from './calendar.svg'
import handleImageUrl from "../../../hooks/useImageUrl";
import { Link } from "react-router-dom";
import { Button } from "../../../components";
type ItemProps = {
  item:any,
  index:any,
}

const ArticleCardComponent:FC<ItemProps> = ({item, index}) => {
  const [width, setWidth] = useState<any>();
  useEffect(() => {
    setWidth(window.outerWidth)
  },[])
  console.log('index', index);
  return ( 
    <Link
      style={index === 0 ? {minWidth:'100%', textDecoration:"none"} : {textDecoration:"none", flex:'1 auto'}}
      to={
        {
          pathname: `${item._id}`,
          // query: { id: item.slug ? item.slug : item.title },
        }
      }
    >
      <ArticleCard style={index === 0 ? {} : {flex:'1 auto'}}>
        <ContentWrapper>
          <ArticlesImg src={handleImageUrl(item.icon)}/>
          <TitleWrapper>
            <ArticleTitle>
              {item.title}
            </ArticleTitle>
              {/* <ArticleSubTitle >
                {new Date(item?.createdOn)?.toISOString().split('T')[0]}
              </ArticleSubTitle> */}
            <DateWrapper>
              <DateIcon src={CalendarIcon}/>
              <ArticleSubTitle >
                {new Date(item?.createdOn)?.toISOString().split('T')[0]}
              </ArticleSubTitle>
            </DateWrapper>
          </TitleWrapper>
        </ContentWrapper>
        {/* <Button title={'SEE MORE'} onClick={() => {}}/> */}
      </ArticleCard>
    </Link>
  );
}
 
export default ArticleCardComponent;