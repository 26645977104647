import { Container, FieldWrapper, StepTwo } from "./styles";
import React, {FC, useState, useEffect} from "react";
import { useLocalStorage } from "../../hooks/useLocalStorage";
import {SpinnerLoader, DefaultCopyField, Button} from '../../components'
import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import StepContent from '@mui/material/StepContent';
// import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import { useNavigate, useParams } from "react-router-dom";
import { orderService } from "../../api";
import Countdown from 'react-countdown';
// import {DefaultCopyField} from '@eisberg-labs/mui-copy-field';
import { useTranslation } from "react-i18next";
import LandscapeRectangle from './infoBlock'
import { keyframes } from '@mui/styled-engine';
import { ToastContainer, toast } from 'react-toastify';
import CancelMessage from './cancelledOrder'

const appear = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

const OrderList:FC = () => {
  const [t] = useTranslation()
  const navigate = useNavigate()
  const [loading, setLoading] = useState<boolean>(false)
  const [activeStep, setActiveStep] = React.useState(0);
  const [pageLoading, setPageLoading] = useState(true)
  const [order, setOrder] = useState<any>({})
  const [statuses, setStatuses] = useState<any[]>([])
  const [cancelled, setCancelled] = useState<boolean>(false)
  const {orderId} = useParams()
  const getOrder = async () => {
    if(orderId){
      try{
        const res = await orderService.getOrderById(orderId)
        await setOrder(res.data)
        await setPageLoading(false)
        if(!res.data?.status) {
          CancelTimeoutPayment()
          return
        }
        if(res.data.status.slug === 'payment'){
          setActiveStep(0)
          setCancelled(false)
        } else if(res.data.status.slug === 'verification') {
          setActiveStep(1)
          setCancelled(false)
        } else if (res.data.status.slug === 'money_transfer') {
          setActiveStep(2)
          setCancelled(false)
        } else if(res.data.status.slug === 'order_close') {
          setActiveStep(3)
          setCancelled(false)
        }else if(res.data.status.slug === 'cancel_order') {
          setCancelled(true)
        }
      } catch (e:any) {
        console.log('err',e);
        toast.error(e?.response?.data?.message || t("ERROR_OCCURED"), {
          position: "bottom-right",
          autoClose: 5000,
          // hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
        // navigate(-1)
      }
    }
  }
  useEffect(() => {
    getOrder()
    orderService.getStatuses()
    .then((res) => {
      setStatuses(res.data)
    })
    .catch((err) => {
      console.log('err', err);
    })
    const interval = setInterval(() => {
      getOrder()
    }, 1000)
    return () => {
      clearInterval(interval)
    }
  },[orderId])
  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };
  const confirmPayment = async () => {
    if(orderId){
      try{
        setLoading(true)
        const dataToChange = {
          orderId,
          status:statuses.find((status:any) => status.slug === 'verification')
        }
        const res = await orderService.changeStatus(dataToChange)
        const resTimeout = await orderService.setTimeout(dataToChange)
        console.log('res', resTimeout.data);
        // await handleNext()
      } catch (e:any) {
        console.log('err',e);
        toast.error(e?.response?.data?.message || t("ERROR_OCCURED"), {
          position: "bottom-right",
          autoClose: 5000,
          // hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
        navigate(-1)
      } finally {
        setLoading(false)
      }
    }
  }

  const CancelTimeoutPayment = async () => {
    if(orderId){
      try{
        const dataToChange = {
          orderId,
          status:statuses.find((status:any) => status.slug === 'cancel_order')
        }
        const res = await orderService.changeStatus(dataToChange)
        getOrder()
        await handleNext()
      } catch (e:any) {
        console.log('err',e);
        toast.error(e?.response?.data?.message || t("ERROR_OCCURED"), {
          position: "bottom-right",
          autoClose: 5000,
          // hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
        // navigate(-1)
      }
    }
  }
  
  const [countDown, setCountDown] = React.useState<any>(0);
  

  function countdown(dateString:any) {
    var date = new Date(dateString);
  
    // Добавляем 30 минут
    date.setMinutes(date.getMinutes() + 30);
    
    // Устанавливаем интервал в 1 секунду
    var interval = setInterval(function() {
      var now = new Date().getTime();
      var distance = date.getTime() - now;
      
      // Рассчитываем минуты и секунды
      var minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
      var seconds = Math.floor((distance % (1000 * 60)) / 1000);
      
      // Форматируем вывод минут и секунд
      var timeString = minutes.toString().padStart(2, '0') + ":" + seconds.toString().padStart(2, '0');
      setCountDown(minutes.toString().padStart(2, '0') + ":" + seconds.toString().padStart(2, '0'))
      // Выводим оставшееся время
      console.log(timeString);
      
      // Проверяем, достигли ли 0
      if (distance < 0 && order?.status.slug !== 'cancel_order') {
        clearInterval(interval);
        console.log("Время истекло!");
        setCountDown("Время истекло!")
        setTimeout(() => {
          CancelTimeoutPayment()
        },5000)
      }
    }, 1000);
  }
  useEffect(() => {
    if(order?.timeout && order.status.slug === 'verification'){
      countdown(order?.timeout)
    }
  },[order])
  const steps = [
    {
      label: `${t("PAYMENT")}`,
      description: (
          <FieldWrapper>
            <DefaultCopyField
              label={t("WALLET_ADDRESS")}
              value={order?.wallet_now || order?.from?.wallet}
            />
            <DefaultCopyField
              label={t("QUANTITY_TO_TRANSFER")}
              value={order?.amount}
            />
            {order?.memo && 
              <DefaultCopyField
                label={t("MEMO")}
                value={order?.memo}
              />
            }
            <Button
              title={t("CONFIRM_PAYMENT")}
              onClick={confirmPayment}
              loading={loading}
            />
          {/* <Typography sx={{color:"#000"}} variant="h4">
            {countDown !== 0 && countDown}
          </Typography> */}
          </FieldWrapper>
      ),
    },
    {
      label:`${t("VERIFICATION")}`,
      description:(
        <StepTwo>
          <SpinnerLoader/>
          <Typography variant="h4">
            {/* @ts-ignore */}
            {countDown !== 0 && countDown}
          </Typography>
          <Typography sx={{fontSize:'20px'}} variant="h4">
            {t("WE_ARE_CURRENTLY_REVIEWING_FUNDS")}
          </Typography>
        </StepTwo>
      )
    },
    {
      label:`${t("MONEY_TRANSFER")}`,
      description:(
        <StepTwo>
          <SpinnerLoader/>
          <Typography sx={{fontSize:'20px'}} variant="h4">
            {t("WE_ARE_CURRENTLY_TRANSFER_MONEY_FOR_YOU")}
          </Typography>
        </StepTwo>
      )
    },
    {
      label:`${t("ORDER_IS_CLOSE")}`,
      description:(
        <StepTwo>
            <Button
              title={t("GO_BACK")}
              onClick={() => navigate('/')}
            >
              
            </Button>
        </StepTwo>
      )
    },
  ];
  if(cancelled) return (
    <Container style={{justifyContent:'center'}}>
      <CancelMessage
         title={t("ORDER_IS_CANCELLED")}
         description={t("ORDER_IS_CANCELLED_DESCRIPTION")}
      />
    </Container>
  )
  if(pageLoading) return <></>;
  return (
    <Container>
      <Box
      sx={{
        display: 'flex',
        alignItems:'flex-start',
        justifyContent: 'flex-start',
        animation: `${appear} 1s ease-in-out`,
        width:'100%',
        // flex: 'none',
        flexBasis:'0',
        // flexGrow: '1',
      }}>
        <Stepper
          style={{minWidth:"100%"}}
          activeStep={activeStep} orientation="vertical">
          {steps.map((step, index) => (
            <Step sx={{width:"100%",display:'flex', flexDirection:"column", alignItems:'flex-start', justifyContent: 'flex-start'}} key={step.label}>
             <StepLabel sx={{width:"100%",display:'flex', alignItems:'flex-start', justifyContent: 'flex-start' }}>
                <Typography sx={(index) < activeStep ? {opacity:'0.3', color:'grey',fontSize:'28px'} : {fontSize:'28px'}} variant="h4">
                  {step.label}
                </Typography>
              </StepLabel>
              <StepContent>
                {step.description}
              </StepContent>
            </Step>
          ))}
        </Stepper>
      </Box>
      <LandscapeRectangle 
        order={order}
        width="500px" height="700px"/>
    </Container>
  )
}


export default OrderList