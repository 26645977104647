import { createTheme } from '@mui/material';
import { fontFamily } from '@mui/system';


const defaultTheme = createTheme({
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          // width: '360px',
          padding:'8px 12px',
          height: '48px',
        },
        outlined: {
          width: '67px',
          // background: 'rgba(245, 0, 87, 0.08)',
        },
      },
    },
    MuiAvatar:{
      styleOverrides:{
        root:{
          fontSize:'28px',
          textTransform:'uppercase',
          width:'50px',
          height:'50px',
          ['@media (max-width:700px)']: { // eslint-disable-line no-useless-computed-key
            // color:'#FFFF',
            height:'50px',
            width:'50px',
            fontSize:'12px',
          }
        }
      }
    },
    MuiTextField:{
      styleOverrides:{
        root:{
          // background: 'rgba(244, 243, 255, 0.1);',
          // width:'360px',
          // border: '1px solid #000000',
          // borderRadius: '10px',
          // maxHeight:'30px',
          color:'#FFF',
          ['@media (max-width:700px)']: { // eslint-disable-line no-useless-computed-key
            // color:'#FFFF',
            height:'10px'
          }
        },
      }
    },
    MuiCircularProgress:{
      styleOverrides:{
        root:{
          // color:'white',
        }
      }
    },
    MuiPaper:{
      styleOverrides:{
        root:{
          borderRadius:'16px'
        }
      }
    },
    MuiIconButton:{
      styleOverrides:{
        root:{
          ['@media (max-width:700px)']: { // eslint-disable-line no-useless-computed-key
            // color:'#FFFF',
            padding:'5px'
          }

          // color:'white',
          // ['@media (max-width:700px)']: { // eslint-disable-line no-useless-computed-key
          //   color:'#FFFF',

          //   '.Mui-focusVisible &': {
          //     borderColor:'#FFFF',
          //     backgrounColor:"#FFFF"
          //   },
          // }
        }
      }
    },
    MuiCheckbox:{
      styleOverrides:{
        root:{
          ['@media (max-width:700px)']: { // eslint-disable-line no-useless-computed-key
            // color:'#FFFF',

            '.Mui-focusVisible &': {
              // borderColor:'#FFFF',
              // backgrounColor:"#FFFF"
            },
          }
        }
      }
    },
    MuiSvgIcon:{
      styleOverrides:{
        root:{
          ['@media (max-width:700px)']: { // eslint-disable-line no-useless-computed-key
            // color:'#FFFF',
            height:'20px'
          }
        }
      }
    },
    MuiTooltip:{
      styleOverrides:{
        // tooltip:{
        //   // backgroundColor:'#000',
        //   color:'#000',
        // }
      }
    },
    MuiStepLabel:{
      styleOverrides:{
        root:{
          // color:'#FFFF !important',
        }
      }
    },

    
  },
  typography: {
    fontFamily: 'Manrope',
    
    h1: {
      fontSize: '64px',
      fontWeight:500,
      color: '#FFFF',
      marginBottom:'60px',
      wordBreak:'break-word',
      ['@media (max-width:700px)']: { // eslint-disable-line no-useless-computed-key
        fontSize: '36px',
        marginBottom:'30px',
        marginTop:'30px',
      }
    },
    h2: {
      textTransform: 'capitalize',
      fontWeight:600,
      color: '#FFFF',
      fontSize: '64px',
      marginRight:'20px',
      wordBreak:'break-word',
      '&:hover': {
        cursor: 'pointer',
      },
      ['@media (max-width:700px)']: { // eslint-disable-line no-useless-computed-key
        fontSize: '32px',
        marginBottom:'20px',
      }
    },
    h3: {
      fontSize:'64px',
      color: '#FFFF',

      // marginBottom:'30px',
      fontWeight:500,
      // lineHeight:'24px',
      ['@media (max-width:700px)']: { // eslint-disable-line no-useless-computed-key
        fontSize: '32px',
      }
    },
    h4: {
      fontSize: '18px',
      // fontFamily: 'Poppins',
      color: '#FFFF',
      wordBreak:'break-word',
      textDecoration:'none !important',
      ['@media (max-width:700px)']: { // eslint-disable-line no-useless-computed-key
        fontSize: '14px',
      }
    },
    h5: {
      fontWeight: 500,
      fontSize: '14px',
      color: '#000',
      wordBreak:'break-word',
      textTransform:'uppercase',
      // marginRight:'12px',
      // padding:'8px',
      // '&:hover': {
      //   cursor: 'pointer',
      //   paddingBottom:'7px',
      //   borderBottom:'1px solid #FFFF'
      // },
      // '&:active':{
      //   color:'#90B6FF',
      //   borderBottom:'1px solid #90B6FF'
      // }
    },
    h6: {
      fontWeight: 600,
      fontSize: '28px',
      color: '#000',
      wordBreak:'break-word',
    },
    subtitle1: {
      fontSize:'22px',
      color: '#FFFF',
      '&:hover': {
        cursor: 'pointer',
      },
    },
    subtitle2: {
      fontSize: '22px',
      color: '#FFFF',
      textDecoration: 'none !important' ,
      textTransform:'uppercase',
      ['@media (max-width:700px)']: { // eslint-disable-line no-useless-computed-key
        fontSize: '12px',
      }
    },
    body1: {
    },
    body2: {
      fontSize:"14px",
      color:'#FFFF',
      textAlign:'center',
      ['@media (max-width:700px)']: { // eslint-disable-line no-useless-computed-key
        fontSize: '12px',
      }
    },
    caption:{
      color: '#FFFF',
    }
  },
  palette: {
    primary: {
      main: '#000',
    },
    secondary: {
      // main: '#FFFFF',
      main: 'rgb(247, 197, 110)',
    },
  },
  spacing: 8,
});

export default defaultTheme;
