import React, { useEffect, useRef, useState } from 'react';
import currencyService from '../api/currencyService';
import * as yup from 'yup';
import { useFormik } from 'formik';
import { addingApiService, orderService } from '../api';
import {validateCryptoAddress} from '../utils/validateWalletCoin'
import { useTranslation } from 'react-i18next';
import { useLocalStorage } from '../hooks/useLocalStorage';
import { useNavigate } from 'react-router-dom';
import { useAuth } from './auth.context';
import axios from 'axios';
interface CryptoContextInterface {
  currencies:any[],
  loadingcurrencies:boolean,
  convertCurrencies:(props:ConvertProp) => void,
  formikProps:any,
  currency1:any,
  setCurrency1:(set:any) => void,
  currency2:any,
  setCurrency2:(set:any) => void,
  revert:boolean,
  setRevert:(set:boolean) => void,
  rateReverted:string,
  setRateReverted:(set:any) => void,
  scrollTo:any,
  setScrollTo:(to:any) => void,
  loadingOrder:boolean,
}
type ConvertProp = {
  from:string,
  to:string,
  depth:string|number,
}

const CryptoContext = React.createContext<Partial<CryptoContextInterface>>({});

const CryptoProvider = (props:any) => {
  const navigate = useNavigate()
  const {user} = useAuth()
  const [currencies, setCurrencies] = useState<any[]>([])
  const [currency1, setCurrency1] = useState<any>('')
  const [currency2, setCurrency2] = useState<any>('')
  const [revert, setRevert] = useState<boolean>(false)
  const [rate, setRate] = useState<any>()
  const [rateReverted, setRateReverted] = useState<any>()
  const [loadingOrder, setLoadingOrder] = useState<boolean>(false)
  const [t] = useTranslation()
  const [initialValues, setInitialValues] = useState<any>({
    field_left:1,
    field_right:0,
    email:'',
    fio:'',
    requisites:'',
  })
  useEffect(() => {
    const noParsed:any = localStorage.getItem('valuesOrder')
    const lastData = JSON.parse(noParsed)
    console.log('lastData', lastData);
    setCurrency1(lastData?.currency1)
    setCurrency2(lastData?.currency2)
    setInitialValues({
      field_left:lastData?.field_left || 1,
      field_right:lastData?.field_right,
      email:lastData?.email || '',
      fio:lastData?.fio || '',
      requisites:lastData?.requisites || '',
    })
  },[])
  const {setDataToLs, getDataFromLs, removeItemFromLs} = useLocalStorage()
  const [scrollTo, setScrollTo] = useState<string>()
  console.log('window.location',window.location);
  const onSubmit = async (values:any) => {
    await setLoadingOrder(true)
    try {
      await axios.get("https://ipapi.co/json/")
      .then( async (resIP) => {
        const data = await {
          from:currency1._id,
          to:currency2._id,
          amount:formikProps.values.field_left,
          toExchange:formikProps.values.field_right,
          email:values.email,
          fio:values.fio,
          wallet:values.requisites,
          user:user?._id ? user._id : null,
          ip:resIP?.data?.ip,
          country:resIP?.data?.country_name,
          domain:window.location?.hostname,
          leadChatId:user?.userChat || getDataFromLs({key:'chatId'})?.chatId
        }
        const res = await orderService.createOrder(data)
          await setDataToLs(['lastOrder', res.data._id])
          await localStorage.removeItem('valuesOrder')
          await setInitialValues({
            field_left: 1,
            field_right:0,
            email: '',
            fio: '',
            requisites: '',
          })
          await navigate(`orders/${res.data._id}`)
      })
      .catch(async (err) => {
        console.log('kek', err);
        const data = await {
          from:currency1._id,
          to:currency2._id,
          amount:formikProps.values.field_left,
          toExchange:formikProps.values.field_right,
          email:values.email,
          fio:values.fio,
          wallet:values.requisites,
          user:user?._id ? user._id : null,
          domain:window.location?.hostname,
          leadChatId:user?.userChat || getDataFromLs({key:'chatId'})?.chatId
        }
        const res = await orderService.createOrder(data)
          await setDataToLs(['lastOrder', res.data._id])
          await localStorage.removeItem('valuesOrder')
          await setInitialValues({
            field_left: 1,
            field_right:0,
            email: '',
            fio: '',
            requisites: '',
          })
          await navigate(`orders/${res.data._id}`)
      })

    } catch (err) {
      console.log('error', err);

    } finally {
      setLoadingOrder(false);
    }
  }
  
  const {
    values,
    errors,
    touched,
    handleSubmit,
    handleChange,
    handleBlur,
  } = useFormik({
    enableReinitialize: true,
    validationSchema: yup.object().shape({
      field_left: currency1 ? yup.number().min(currency1?.exchangeMinSum, `${t("THE_VALUE_MUST_BE_AT_LEAST")} ${currency1?.exchangeMinSum}`).max(currency1?.exchangeMaxSum, `${t("THE_VALUE_MUST_BE_NO_MORE")} ${currency1?.exchangeMaxSum}`).required(`${t("REQUIRED_FIELD")}`) : yup.number(),
      field_right: currency2 ? yup.number().min(currency2?.exchangeMinSum, `${t("THE_VALUE_MUST_BE_AT_LEAST")} ${currency2?.exchangeMinSum}`).max(currency2?.exchangeMaxSum, `${t("THE_VALUE_MUST_BE_NO_MORE")} ${currency2?.exchangeMaxSum}`).required(`${t("REQUIRED_FIELD")}`) : yup.number(),
      email: yup.string().email(`${t("NOT_VALID_EMAIL")}`).required(`${t("REQUIRED_FIELD")}`),
      fio: yup.string().required(`${t("REQUIRED_FIELD")}`),
      requisites: yup.string().required(`${t("REQUIRED_FIELD")}`),
      // validateCryptoAddress(
      //   currency2,
      //   t,
      // ),
    }),
    initialValues: {
      ...initialValues,
    },
    onSubmit,
  });
  useEffect(() => {
    localStorage.setItem('valuesOrder', JSON.stringify({...values, currency1:currency1, currency2:currency2}))
  },[values, currency1,
    currency2])
  const formikProps = {
    errors,
    values,
    touched,
    handleChange,
    handleBlur,
    handleSubmit
  };


  const [loadingcurrencies, setLoading] = useState(true);
  const getCurrencies = () => {
    // addingApiService.getAlerts()
    currencyService.getCurrency()
    .then((res) => {
      setCurrencies(res.data.map((currency:any) => {
        return {
          ...currency,
          value:currency?._id,
          label:currency?.name
        }
      }));
      // setCurrency1(res.data[0]._id)
      // setCurrency2(res.data[1]._id)
      setLoading(false);
    })
    .catch((err) => {
      console.log('error getting currencies', err);
    })
  }


  
  const convertCurrencies = () => {
    const dataToConvert = {
      from:currency1?._id,
      to:currency2?._id,
      depth:values['field_left'],
    }
    addingApiService.getCurrencies(dataToConvert)
    .then((res) => {
      //@ts-ignore
      // handleChange('field_right')(res.data.price)
      // console.log('calculate',calculateExchangeRate(values['field_left'], res.data.price, res.data?.reverted));
      // setRate(calculateExchangeRate(values['field_left'], res.data.price, res.data?.reverted))
      setRate(+res.data.price)
      // setRateReverted(+res.data.responsePairs[0].price)
    })
    .catch((err) => {
      console.log('res',err);
      setRate(0)
    })
  }
  useEffect(() => {
    handleChange('field_right')(isNaN(values.field_left * rate) ? '0' : (values.field_left * rate).toString())
  },[rate, values.field_left])
  // console.log('rate' ,rate);
  useEffect(() => {
    getCurrencies()
    const interval = setInterval(() => {
      getCurrencies()
    },5000)
    return () => {
      clearInterval(interval)
    }
  },[])
  useEffect(() => {
    if(currency1 && currency2){
      convertCurrencies()
    }
  },[currency1, currency2])


  return (
    <CryptoContext.Provider
      value={{
        currencies,
        loadingcurrencies,
        convertCurrencies,
        formikProps,
        currency1,
        setCurrency1,
        currency2,
        setCurrency2,
        revert,
        setRevert,
        rateReverted,
        setRateReverted,
        scrollTo,
        setScrollTo,
        loadingOrder
      }}
      {...props}
    />
  );
};

const useCrypto = () => {
  const context = React.useContext(CryptoContext);
  if (context === undefined) {
    throw new Error('useCrypto must be used within a AuthProvider');
  }
  return context as CryptoContextInterface;
};

export { CryptoProvider, useCrypto };
