import styled from "styled-components";

export const Container = styled.div`
  display:flex;
  flex-direction: column;
  gap:15px;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  background-color:#101010;
  padding:80px 120px 300px 120px;
  width: 100%;
  border-top-left-radius: 50px;
  border-top-right-radius: 50px;
  @media screen and (max-width: 700px){
    padding:35px 30px 125px 30px;
    border-top-left-radius: 30px;
    border-top-right-radius: 30px;
  }
`

export const FaqBox = styled.div`
  display:flex;
  justify-content: space-between;
`

export const FaqQuestion = styled.img`
  
`