import styled from "styled-components";


type ActiveProp = {
  isSmall?:boolean
}

export const PageWrapper = styled.div<ActiveProp>`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  /* @media screen and (max-width: 700px){
    margin-top:80px;
  } */
`

export const RouterWrapper = styled.div`
  background-color: ${({theme}) => theme.base.white};
  display: flex;
  justify-content: flex-start;

`


export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 30%;
  height:100vh;
`
export const LinkWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
`