import styled, { keyframes } from 'styled-components';

interface RectangleProps {
  width?: string;
  height?: string;
}

const appear = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;


export const Rectangle = styled.div<RectangleProps>`
  width: ${props => props.width || '50%'};
  /* height: ${props => props.height || '80%'}; */
  border-radius: 16px;
  gap:20px;
  padding:20px 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
  box-sizing: border-box;
  background-color: #FFF;
  animation: ${appear} 0.5s ease-in-out;
`;
export const Container = styled.div`
  display:flex;
  /* margin-top:10px; */
  /* flex-direction: column; */
  gap:100px;

  justify-content:center;
  align-items:flex-start;
  /* width: 100%; */
  flex-wrap:wrap;
  padding:40px 80px 40px 80px;
  @media screen and (max-width: 700px){
    padding:22px 22px 22px 22px;
  }
`

export const FieldWrapper = styled.div`
  display: flex;
  flex-direction: column;
  background-color: #fff;
  padding: 20px;
  /* width: 100%; */
  border-radius:8px;
  flex: none;
  flex-basis:0;
  flex-grow: 1;
  margin-left:20px ;
  width: 460px;
  /* max-width: 460px; */
  gap:20px;
  @media screen and (max-width: 700px){
    width: 60vw; 
    /* min-width: 160px;
    max-width: 200px; */
  }
`
export const StepTwo = styled.div`
  padding-bottom:10px;
  display:flex;
  flex-direction:column;
  justify-content: center;
  width: 460px;
  min-height:200px;
  @media screen and (max-width: 700px){
    width: 60vw; 
    /* min-width: 160px;
    max-width: 200px; */
  }
`
export const InfoBlockItem = styled.div`
  display: flex;
  flex-direction: column;
  
  width: 100%;
  gap:10px;
  /* align-items: flex-start; */
`

export const ValueField = styled.div`
  font-family: Manrope;
  font-style: normal;
  font-weight: 300;
  font-size: 22px;
  line-height: 140%;
  color: #101010;
  border-bottom: 1.5px solid #87A9FF;
  display: flex;
  justify-content: center;
  box-sizing: border-box;
  padding:10px 10px;
  width: 60%;
`

export const ValueContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content:space-between;

`

export const LabelTitle = styled.p`
  font-family: Manrope;
  font-style: normal;
  margin-bottom:0px;
  font-weight: 300;
  font-size: 18px;
  color: #6B6B6B;
`