import * as yup from 'yup';
import {ValidationKit} from '../../Kits';

const {isRequired, isInvalidEmail, areEqualValues} = ValidationKit;

const UserSchema = yup.object().shape({
  email: yup.string().email(isInvalidEmail()).required(isRequired()),
  name:yup.string().required(isRequired()),
  password: yup.string(),
});

export default UserSchema;
