import apiClient from './apiClient'
export type ArticleProps = {
  title?:string,
  message?:string,
  author?:string,
  isMain?:boolean,
  image?: string,
  _id?:string,
}
const articleService = {
  getAllBlog:(page?:number, chapter?:string) => apiClient.get(`blog?page=${page}`),
  getArticleById:(id:any) => apiClient.get(`blog/${id}`),
  // getMainArticle:() => apiClient.get('articles/main'),
  // addArticle:(data:ArticleProps) => apiClient.post('articles', data),
  // updateArticle:(data:ArticleProps) => apiClient.patch(`articles/${data._id}`, data),
  // deleteArticle:(id:string) => apiClient.delete(`articles/${id}`),
  // getFeaturedArticles:() => apiClient.get('articles/featured')
}
export default articleService