import React, {FC, useState} from 'react';
import {
  Container,
  LeftSide,
  RightSideImage,
  RightSideImageBack,
  FormWrapper,
  Title,
  SubTitle,
  ContactLineWrapper,
  ContactLine,
  ContactIcon,
} from './styles'
import MailImg from './mail-ic.svg'
import TelegramIcon from './telega-ic.svg'
import { useFormik } from 'formik';
import {ContactFormSchema} from './contact-form.schema'
import { useTranslation } from 'react-i18next';
import contactService from '../../../../api/contactService';
import { ToastContainer, toast } from 'react-toastify';
import { useAuth } from '../../../../context/auth.context';
import { useCrypto } from '../../../../context/crypto.context';

const Contacts:FC<any> = () => {
  const [t] = useTranslation()
  const [loading, setLoading] = useState<boolean>(false)
  const [initialValues, setInitialValues] = useState<any>({
    email:'',
    name:'',
    phone:'',
    message:'',
  });
  const {
    config
  } = useAuth()
  const onSubmit = async (values:any) => {
    try{
      setLoading(true)
      const data = await contactService.sendMessage(values)
      toast.info(t("CONTACT_US_FORM_SUCCESSFUL"), {
        position: "bottom-right",
        autoClose: 5000,
        // hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
        });
      resetForm()
    } catch(e:any) {
      toast.error(e?.response.data.message, {
        position: "bottom-right",
        autoClose: 5000,
        // hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
        });
    } finally {
      setLoading(false)
    }
  }
  const {
    values,
    errors,
    touched,
    isSubmitting,
    handleSubmit,
    handleChange,
    resetForm,
  } = useFormik({
    enableReinitialize: true,
    validationSchema: ContactFormSchema,
    initialValues,
    onSubmit,
  });
  const formikProps = {
    errors,
    values,
    touched,
    handleChange,
  };
  return (
    <Container id='contact-us'>
      <LeftSide>
        <Title>
          {t("OUR_CONTACTS")}
        </Title>
        <SubTitle>
          {t("OUR_CONTACTS_DESC")}
        </SubTitle>
      </LeftSide>
      <LeftSide>

        <ContactLineWrapper>
          <ContactIcon src={MailImg}/>
          <ContactLine>
            {config?.email}
          </ContactLine>
        </ContactLineWrapper>
        <ContactLineWrapper>
          <ContactIcon src={TelegramIcon}/>
          <ContactLine
            target="_blank"
            rel="noreferrer"
            href={config?.telegram?.url}
          >
            {t("CONTACT_US")}
          </ContactLine>
        </ContactLineWrapper>
      </LeftSide>
    </Container>
  );
}
 
export default Contacts;