import { Container } from "./styles";
import React, {FC, useState, useEffect} from "react";
import { useLocalStorage } from "../../hooks/useLocalStorage";
import { orderService } from "../../api";
import { useNavigate, useParams } from "react-router-dom";
import { ToastContainer, toast } from 'react-toastify';
import { useTranslation } from "react-i18next";

const OrderPage:FC = () => {
  const [orders, setOrders] = useState<any[]>([])
  const [t] = useTranslation()
  const navigate = useNavigate()
  const getOrders = async () => {
    try{
      const res = await orderService.getList()
      await setOrders(res.data)
    } catch (e:any) {
      console.log('err',e);
        toast.error(e?.response?.data?.message || t("ERROR_OCCURED"), {
          position: "bottom-right",
          autoClose: 5000,
          // hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
    }
  }
  useEffect(() => {
    getOrders()
    const interval = setInterval(() => {
      getOrders()
    },2000)
    return () => {
      clearInterval(interval)
    }
  },[])
  return (
    <Container>
      {orders.map((order:any) => (
        <div>
          {order.email}
        </div>
      ))}
    </Container>
  )
}


export default OrderPage