import React, {FC} from 'react'
import {
  ArticleCard,
  TitleWrapper,
  UserInfoWrapper,
  DateWrapper,
  TextComment,
} from './styles'
import { Typography, Avatar, Divider } from "@mui/material";
import Rating from '@mui/material/Rating';
import DateView from '../DateView';
import handleImageUrl from '../../hooks/useImageUrl';
import useDescriptionSlice from '../../hooks/useDescriptionSlice';

type CardProps = {
  user:any,
  textComment:string,
  order?:any,
  _id:string,
  rating?:number,
  stars?:number,
  text?:string,
  createdOn:Date,
  name:string,
  email:string,
}
const ReviewCard:FC<CardProps> = (props) => {
  const {
    user,
    textComment,
    text,
    order,
    rating,
    stars,
    createdOn,
    name,
    email,
    _id,
  } = props
  return (
    <ArticleCard key={_id}>
      <UserInfoWrapper>
      <DateWrapper>
        <Avatar
          style={{
            marginRight:'20px',
            marginTop:'10px',
            fontSize:'28px'
          }}
          alt={user?.name}
          src={handleImageUrl(user?.image)}
        />
          {user ? 
            <Typography variant="h4">
            {user?.name || user?.email}
            </Typography>
          : 
            <Typography variant="h4">
              {name || email}
            </Typography>
          }
        </DateWrapper>
      </UserInfoWrapper>
        <TitleWrapper>
        <TextComment>
          {useDescriptionSlice(textComment, 200) || text}
        </TextComment>
      </TitleWrapper>
    </ArticleCard>
  )
} 

export default ReviewCard 
