import styled from "styled-components";

export const Box = styled.div`
  display:flex;
  flex-direction: column;
  padding:120px;
  @media screen and (max-width: 700px){
    padding: 12px;
    margin-bottom:80px;
    justify-content: center;
  }
`

export const Container = styled.div`
  width:100%;
  min-height:100vh;
  display:flex;
  box-sizing: border-box;
  gap:50px;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap:wrap;
  align-items: flex-start;
  @media screen and (max-width: 700px){
    flex-direction: column;
    align-items: center;
    min-height:100%;
    gap:20px;
  }
`
export const ProfileButtonsWrapper = styled.div`
  display: flex;
  @media screen and (max-width: 700px){
    flex-direction: column;
    justify-content: space-between;
    height: 130px;
  }
`
export const Label = styled.label`
  position: relative;
  display: flex;
  justify-content: center;
  max-width:180px;
  /* background: #000; */
  &:hover{
      cursor: pointer;
  }
  /* @media screen and (max-width: 700px){
    width:20%;
  } */
`
export const AvatarInput = styled.input`
  display:none;
  
`

export const ProfileWrapper = styled.div`
  display: flex;
  flex-direction: column;
  border-radius: 8px;
  background-color: #FFF;
  /* width: 500px; */
  padding:20px;
  box-sizing: border-box;
  @media screen and (max-width: 700px){
    padding:20px;
    width:100%;
  }
`
export const ProfileWrapperMobile = styled.div`
  display: flex;
  flex-direction: column;
  width:400px;
  box-sizing: border-box;
  /* margin-left:120px; */
  /* padding:20px; */
  @media screen and (max-width: 700px){
    width:100%;
    /* padding-left:20px;
    padding-right:20px; */
    
  }
`
export const ProfileImageEdit = styled.img`
  width:45px;
  height: 45px;
  object-fit: contain;
  position: absolute;
  /* box-shadow: 0px 20px 45px rgba(0, 0, 0, 0); */
  margin-top:65px;

`

export const ProfileImage = styled.img`
  width:180px;
  height: 180px;
  object-fit: contain;
  margin-bottom: 80px;
  border: 1px solid black;
  border-radius: 8px;
  /* box-shadow: 0px 20px 45px #b8b5d8; */
  @media screen and (max-width: 700px){
    width:150px;
    height: 150px;
  }
`
export const ButtonWrapper = styled.div`
  width:180px;
  margin-left:20px;
`

export const OrderWrapper = styled.div`
  display: flex;
  flex-direction: column;
`
export const ProductWrapper = styled.div`
  display:flex;
  flex-direction: column;
  &:hover {
    cursor:pointer;
  }
`

export const InputContainer = styled.div`
  height:100px;
  @media screen and (max-width: 700px){
    height: 59px;
  }
`