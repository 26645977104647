import { Typography } from "@mui/material";
import { FC, useRef, useEffect, useState } from "react";
import {Container, ArrowStyle} from './styles'
import Arrow from './arrow.svg'
import Loader from "../Loader";
import CircularProgress from '@mui/material/CircularProgress';
type ButtonProps = {
  onClick:any,
  title:string,
  isArrow?:boolean,
  loading?:boolean,
  disabled?:boolean,
  children?:any,
  customStyles?:any,
}
const Button:FC<ButtonProps> = (props) => {
  const [hover, setHover] = useState<any>(false)
  const {
    onClick,
    title,
    isArrow,
    loading,
    disabled,
    customStyles
  } = props
  if(loading) return <Container type='button' style={customStyles}><CircularProgress /></Container>
  return (
    <Container
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
      onClick={onClick}
      style={customStyles}
      disabled={disabled}
      type='button'
    >
      {title}
      {isArrow ? <ArrowStyle hover={hover} src={Arrow}/> : null}
    </Container>
  );
}

export default Button;