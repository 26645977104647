import * as yup from 'yup';
import {ValidationKit} from '../../Kits';

const {isRequired, isInvalidEmail, areEqualValues} = ValidationKit;

export const ResetPasswordEmailSchema = yup.object().shape({
  email: yup.string().email(isInvalidEmail()).required(isRequired()),
});





