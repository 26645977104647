import React, {FC, useEffect, useContext, useState} from 'react';
import Button from '../Button';
import SignUpSchema from './signUp.schema'
import {Input} from '../../components'
import { useFormik } from 'formik';
// import {authService} from '../../Api'
import {
  SubTitleWrapper,
  Nav,
  Terms,
  TermsWrapper,
  StyledBox
} from './styles'
import { authService } from '../../api';
import { setClientToken } from '../../api/apiClient';
import { useLocalStorage } from '../../hooks/useLocalStorage';
import { ToastContainer, toast } from 'react-toastify';
import { useAuth } from '../../context/auth.context';
import { TransitionProps } from '@mui/material/transitions';
import Slide from '@mui/material/Slide';
import Dialog from '@mui/material/Dialog';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import * as yup from 'yup';
const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>,
) {
  return <Slide direction="down" ref={ref} {...props} />;
});

type SignUpProps = {
  isOpen:boolean,
  handleClose:() => void,
  goToAnother:() => void,
}

const SignUp:FC<SignUpProps> = (props) => {
  const [t] = useTranslation()
  const navigate = useNavigate()
  // const { setAlert } = useContext(UIContext);
  const [initialValues, setInitialValues] = useState<any>({
    email: '',
    password: '',
    name:'',
    confirmPassword:'',
  });
  const [loading, setLoading] = useState<boolean>(false)
  const [width, setWidth] = useState<any>();
  const {updateUser} = useAuth()

  const {
    setDataToLs,
    getDataFromLs,
  } = useLocalStorage()

  const Submit = async (data: any) => {
    setLoading(true)
    let postData = {
      email:data.email,
      password:data.password,
      name:data.name,
      userChat:getDataFromLs({key:'chatId'})?.chatId
    }
    authService.signUp(postData)
    .then(async (res:any) => {
      console.log('res',res)
      await setClientToken(res.data.token)
      await setLoading(false)
      await handleClose()
      await setDataToLs([
        'authToken',
        res.data.token,
      ])
      await updateUser()
      await navigate('/profile')
    })
    .catch((e:any) => {
      console.log('error', e)
      toast.error(e.response.data.message, {
        position: "bottom-right",
        autoClose: 5000,
        // hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
        });
      setLoading(false)
    })
  };
    const {
      values,
      errors,
      touched,
      isSubmitting,
      handleSubmit,
      handleChange,
      resetForm,
      
    } = useFormik({
      enableReinitialize: true,
      validationSchema: yup.object().shape({
        email: yup.string().email(`${t("NOT_VALID_EMAIL")}`).required(`${t("REQUIRED_FIELD")}`),
        name:yup
        .string()
        .required(`${t("REQUIRED_FIELD")}`)
        .min(3, `${t("THE_VALUE_MUST_BE_AT_LEAST")} 3`)
        .max(16, `${t("THE_VALUE_MUST_BE_NO_MORE")} 16`),
        password: yup
        .string()
        .required(`${t("REQUIRED_FIELD")}`)
        .min(8, `${t("THE_VALUE_MUST_BE_AT_LEAST")} 8`)
        .max(24, `${t("THE_VALUE_MUST_BE_NO_MORE")} 24`)
        .matches(
          /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])/,
          'Must contain one uppercase, one lowercase and one number',
        ),
        confirmPassword:yup.string().required(`${t("REQUIRED_FIELD")}`).oneOf([yup.ref('password')], `${t("PASSWORDS_NOT_MATCH")}`),
      }),
      initialValues,
      onSubmit: (value) => Submit(value),
    });
  const {
    isOpen,
    handleClose,
    goToAnother
  } = props
  const formikProps = {
    errors,
    values,
    touched,
    handleChange,
  };
  useEffect(() => {
    setWidth(window.outerWidth)
    const listener = (event:any) => {
      if (event.code === "Enter" || event.code === "NumpadEnter") {
        event.preventDefault();
        handleSubmit()
      }
    };
    document.addEventListener("keydown", listener);
    return () => {
      document.removeEventListener("keydown", listener);
    };
  }, []);
  return(
    // <Modal
    //   open={isOpen}
    //   onClose={handleClose}
    //   aria-labelledby="modal-modal-title"
    //   aria-describedby="modal-modal-description"
    // >
    //   <Box sx={style}>
    <Dialog
      open={isOpen}
      TransitionComponent={Transition}
      keepMounted
      onClose={handleClose}
      sx={{borderRadius:'50px'}}
    >
      <StyledBox>
        <Input
          fieldName={'email'}
          title={t("EMAIL")}
          customStyles={{color:'#000'}}
          {...formikProps}
        />
        <Input
          fieldName={'name'}
          title={t("NAME")}
          customStyles={{color:'#000'}}
          {...formikProps}
        />
        <Input
          fieldName={'password'}
          title={t("PASSWORD")}
          customStyles={{color:'#000'}}
          {...formikProps}
          isPassword={true}
        />
        <Input
          fieldName={'confirmPassword'}
          title={t("PASSWORD_CONFIRM")}
          customStyles={{color:'#000'}}
          {...formikProps}
          isPassword={true}
        />
        <Button customStyles={{marginTop:'24px'}} loading={loading} onClick={() => handleSubmit()} title={t("SIGN_UP")}></Button>
        {/* <TermsWrapper>
          By clicking Sign Up, you are indicating that you have read and acknowledge  the <br/>
          <Terms onClick={() => window.open('https://docs.google.com/document/d/1aOy3GLtRlVfrtZnSQkE_IKf3iyOeYXCDvFdmr6EuzFU/edit?usp=sharing')}>Terms of Service.</Terms>
        </TermsWrapper> */}
        <SubTitleWrapper>
          <Nav isGrey style={{color:'#DBDBDB'}}>
            {t("ALREADY_HAVE_AN_ACCOUNT")}
          </Nav>
          <Nav onClick={() => goToAnother()}>
            {t("SIGN_IN")}
          </Nav>
        </SubTitleWrapper>

      {/* </Box>
    </Modal> */}
      </StyledBox>
    </Dialog>
  )
}

export default SignUp
