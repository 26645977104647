import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color:#FFF ;
  padding:20px 50px;
  box-sizing: border-box;
  width: 100%;
@media screen and (max-width: 700px){
  padding:10px 20px;
}
`

export const CurrencyContainer = styled.div`
  display: flex;
  flex-direction: row;
  /* justify-content: center; */
  align-items: center;
  gap: 20px;
  background-color: #FFFF;
  /* width: 145px; */
  padding: 16px 22px;
  /* flex-grow:1; */
  /* flex:auto 1; */
  background: #F7F7F7;
  border-radius: 50px;
  /* box-sizing: border-box; */
  /* flex-grow:1; */
  @media screen and (max-width: 700px){
    padding: 12px;
    border-radius: 30px;
    /* width: 100px; */
    gap: 8px;
    
    width: 40%;
    height: 50px;
    border-radius: 100px;
  }
`
export const TitleWrapper = styled.div`
  display: flex;
  /* flex-direction: column; */
  gap:8px;
  text-align: center;
  @media screen and (max-width: 700px){
    flex-direction: column;
    align-items: flex-start;
  }

`
export const CurrenciesWrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap:40px;
  justify-content: center;
  flex-wrap: wrap;
  padding: 50px;
  flex-grow:1;
  /* flex:none; */
  width: 100%;
  @media screen and (max-width: 700px){
    width: 100%;
    gap:15px;
    padding: 10px;
  }
`

export const ItemText = styled.div`
  font-family: Manrope;
  font-style: normal;
  font-weight: 300;
  color: #6B6B6B;
  font-size: 20px;
  opacity: 0.35;
  /* word-wrap: break-word; */
  word-break: break-all;
  color: #000000;
  @media screen and (max-width: 700px){
    font-size: 16px;
  }
`
export const ItemTextBold = styled.div`
  font-weight: 600;
  text-transform: uppercase;
  /* font-style: italic; */
  font-family: Manrope;
  font-size: 20px;
  color: #000000;
  @media screen and (max-width: 700px){
    font-size: 16px;
  }
`
export const ItemImage = styled.img`
  width: 60px;
  height: 60px;
  display: flex;
  flex-direction: column;
  border-radius: 12px;

  @media screen and (max-width: 700px){
    width: 32px;
    height: 32px;
  }
`