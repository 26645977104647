import React,{FC, useEffect, useState} from "react";
import {
  Container,
  ArticleImage,
  SubTitleWrapper
} from './styles'
import { Typography } from "@mui/material";
import { BackArrow, Loader} from "../../components";
import { useNavigate, useParams } from "react-router-dom";
import { blogService } from "../../api";
import handleImageUrl from "../../hooks/useImageUrl";
import { ToastContainer, toast } from 'react-toastify';


const ArticlePage:FC<any> = () => {
  const [loading, setLoading] = useState<boolean>(true)
  const [article, setArticle] = useState<any>({})
  const {articleId} = useParams()
  const navigate = useNavigate()
  const getArticle = () => {
    if(articleId) {
      blogService.getArticleById(articleId)
      .then((res) => {
        setArticle(res.data)
      })
      .catch((err:any) => {
        toast.error(err.response.data.message || 'Error occured', {
        position: "bottom-right",
        autoClose: 5000,
        // hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
        });
        navigate(-1)
      })
      .finally(() => {
        setLoading(false)
      })
    }
  }
  useEffect(() => {
    getArticle()
  },[])

  if(loading || !article) return <Container style={{justifyContent:'center', alignItems:'center'}}><Loader/></Container>
  return(
    <Container>
      <BackArrow/>
      <Typography variant='h2'>{article.title}</Typography>
      <ArticleImage title={article.title} alt={article.title} src={handleImageUrl(article?.icon) || ''}/>
      
      {article?.body ? 
        <p style={{color:"#FFF"}} dangerouslySetInnerHTML={{__html: article?.body}}/>
      :
      null
      }
      <SubTitleWrapper>
        <Typography sx={{fontFamily:"Poppins"}} variant="h3">
          {article.author}
        </Typography>
        <Typography sx={{fontFamily:"Poppins", color:"#FFF"}}>
          {new Date(article?.createdOn).toISOString().split('T')[0]}
        </Typography>
      </SubTitleWrapper>
    </Container>
  )
}


export default ArticlePage