import React,{FC, useState, useContext, useEffect} from 'react'
import {
  CreateRviewSchema,
} from './createReview.schema'
import { ErrorText } from './styles'
import { useFormik } from 'formik';
// import { UIContext } from '../../Context/ui.context';
// import { reviewService } from "../../Api";
// import {Input, Button, Loader, BackArrow} from '../../Components'
import { Typography } from "@mui/material";
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import Rating from '@mui/material/Rating';
import { useAuth } from '../../../../context/auth.context';
import { Button, Input } from '../../../../components';
import * as yup from 'yup';
import { reviewService } from '../../../../api';
import { ToastContainer, toast } from 'react-toastify';
import { useLocalStorage } from '../../../../hooks/useLocalStorage';
import { useTranslation } from 'react-i18next';



type ComponentProps = {
  isOpen:boolean,
  handleClose:() => void,
  updateFunc?:() => void,
  saveFunc?:any,
}


const CreateReviewComponent:FC<ComponentProps> = (props) => {
  const {
    user
  } = useAuth()
  const [width, setWidth] = useState<any>();
  useEffect(() => {
    if(typeof window !== 'undefined'){
      setWidth(window.outerWidth)
    }
  },[])
  const style = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: width < 700 ? '300px' : '516px',
    // height: '434px',
    // width:'300px',
    borderRadius: '8px',
    bgcolor: 'background.paper',
    alignItems:'space-between',
    justifyContent:'space-between',
    boxShadow: 24,
    p: 2,
  };
  const [initialValues, setInitialValues] = useState<any>({
    textComment: '',
  });
  const {
    isOpen,
    handleClose,
    updateFunc
  } = props
  const {
    editArr
  } = useLocalStorage()
  const [t] = useTranslation()
  const [loading, setLoading] = useState<boolean>(false)
  const [rating, setRating] = useState<number | null>(0)
  const [errSetRating, setErrSetRating] = useState<boolean>()
  // const { setAlert } = useContext(UIContext);
  const onSubmit = (values:any) => {
    setLoading(true)
    const data = {
      user:user,
      email:values.email,
      phone:values.phone,
      textComment:values.textComment,
      name:values.name,
    }
    reviewService.createReview(data)
    .then(res => {
        editArr({
          key:'userReview',
          objToSave:res.data,
          action:'edit'
        })
        toast.info(t("REVIEW_FORM_SUCCESSFUL"), {
          position: "bottom-right",
          autoClose: 5000,
          // hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
          });
        handleClose()
        resetForm()
      })
      .catch((e:any) => {
        console.log('e',e);
        toast.error(e.response.data.message, {
          position: "bottom-right",
          autoClose: 5000,
          // hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
          });
      })
      .finally(() => {
        setLoading(false)
      })
  } 
  const {
    values,
    errors,
    touched,
    isSubmitting,
    handleSubmit,
    handleChange,
    resetForm,
  } = useFormik({
    enableReinitialize: true,
    validationSchema: user ? yup.object().shape({
      textComment: yup.string().required(`${t("REQUIRED_FIELD")}`),
    }) : yup.object().shape({
      email: yup.string().email(`${t("NOT_VALID_EMAIL")}`).required(`${t("REQUIRED_FIELD")}`),
      textComment: yup.string(),
      name: yup.string(),
      phone: yup.string().required(`${t("REQUIRED_FIELD")}`),
    }),
    initialValues,
    onSubmit,
  });
  const formikProps = {
    errors,
    values,
    touched,
    handleChange,
  };

  return (
    <Modal
      open={isOpen}
      onClose={handleClose}
    >
      <Box sx={style}>
        <div style={{display:'flex', height:'100%', flexDirection:'column', justifyContent:'space-between',gap:'20px'}}>
          {user ? 
            <Input
              fieldName={'textComment'}
              title={t('WRITE_REVIEW')}
              isReview
              customStyles={{color:'#000'}}
              {...formikProps}
            /> 
          :
            <>
              <Input
                fieldName={'name'}
                title={t("NAME")}
                customStyles={{color:'#000'}}
                {...formikProps}
              />
              <Input
                fieldName={'email'}
                title={t("EMAIL")}
                customStyles={{color:'#000'}}
                {...formikProps}
              />
              <Input
                fieldName={'phone'}
                title={t("PHONE")}
                customStyles={{color:'#000'}}
                {...formikProps}
              />
              <Input
                fieldName={'textComment'}
                title={t("WRITE_REVIEW")}
                customStyles={{color:'#000'}}
                isReview
                {...formikProps}
              />
            </>
          }
          <Button 
            title={t("SEND_REVIEW")}
            onClick={handleSubmit}
            loading={loading}
          />
        </div>
      </Box>
    </Modal>
  )
}

export default CreateReviewComponent