import apiClient from "./apiClient";


const orderService = {
  createOrder:(data:Object) => apiClient.post(`order`, data),
  getOrderById:(orderId:number|string) => apiClient.get(`order?orderId=${orderId}`),
  changeStatus:(data:Object) => apiClient.post(`order/change_status`, data),
  setTimeout:(data:Object) => apiClient.post(`order/set-timeout`, data),
  getStatuses:() => apiClient.get('order/statuses'),
  getList:() => apiClient.get('order/list'),
  searchById:(orderId:number|string) => apiClient.get(`order/search-order?orderId=${orderId}`),
  getOrderForUser:() => apiClient.get(`order/user`),
}


export default orderService
