import { HomePage, ChatList, Blog, ResetPassword, Rules, ArticlePage, OrderPage, OrderList,UserProfile } from "../pages";
import React, { FC } from "react";
import { Routes, Route, Navigate } from 'react-router-dom';
import { useAuth } from "../context/auth.context";
import {PageWrapper} from './styles'
import SpeedDial from '@mui/material/SpeedDial';
import ChatIcon from '@mui/icons-material/Chat';
import { useChat } from "../context/chat.context";
import { ImportContacts } from "@mui/icons-material";
import MarkUnreadChatAltIcon from '@mui/icons-material/MarkUnreadChatAlt';
import Chat from './chat.svg'
import ChatActive from './chat-active.svg'
const RootRouter = () => {
  const {user} = useAuth()
  const {
    chatModal,
    chatReady,
    setChatModal,
    messages,
    handleOpenChat
  } = useChat()
  return (
    <>    
      <PageWrapper>
          
        <Routes>
          {/* <Route path="/mc-widget" element={<Navigate replace to="/matches" />} />
          <Route path="/" element={<Navigate replace to="/matches" />} /> */}
          <Route path="/" element={<HomePage />}/>
          <Route path="*" element={<Navigate to='/'/>}/>
          <Route path="/rules" element={<Rules />}/>
          <Route path="/chat-admin" element={<ChatList />}/>
          <Route path="/blog" element={<Blog />}/>
          <Route path="/blog/:articleId" element={<ArticlePage />}/>
          <Route path="/orders/:orderId" element={<OrderPage />}/>
          <Route path="/orders" element={<OrderList />}/>
          {user ?
          <Route path='/profile' element={<UserProfile/>}/>
          :null
          }
        </Routes>
        {chatModal ? null : !chatReady ? null :
          <SpeedDial
          ariaLabel=""
          sx={{ position: 'fixed', bottom: 16, right: 16 }}
          onClick={handleOpenChat}
          icon={messages.filter((mess:any) => !mess?.isRead && mess?.adminMessage)?.length > 0 ? <img src={ChatActive}/> : <img src={Chat} />}
        ></SpeedDial>
        }
      </PageWrapper>
    </>
  )
}


export default RootRouter;