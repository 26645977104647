import { FC, useCallback, useEffect, useRef, useState } from 'react'
import {
  Container,
  ExchangeWrapper,
  Title,
  ExchangeOrderForm,
  TopForm,
  TopInputForm,
  PlaceHoldeImage,
  CheckBoxWrapper,
  CheckBoxLink,
  ConfirmContainer,
  CaptchaContainer
} from './styles'
import ExchangeForm from './exchange-form'
import {
  Contacts,
  Reserves,
  Partners,
  Reviews,
  Faq,
  TrustBlock,

} from './components'
import { useCrypto } from '../../context/crypto.context';
import { Button, Input } from '../../components';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import handleImageUrl from '../../hooks/useImageUrl';
import Checkbox from '@mui/material/Checkbox';
import {useAuth} from '../../context/auth.context'
import ReCAPTCHA from "react-google-recaptcha";
const HomePage:FC = () => {
  const [t] = useTranslation()
  const [confirmed, setConfirmed] = useState<boolean>(false)
  const {
    currencies,
    formikProps,
    currency1,
    setCurrency1,
    currency2,
    setCurrency2,
    scrollTo,
    setScrollTo,
    loadingOrder
  } = useCrypto()
  const {config} = useAuth()
  const [checked, setChecked] = useState<boolean>(false)

  const handleFormSubmit = async () => {
    try {
      setConfirmed(true)
    } catch (error) {
      setConfirmed(false)
      console.log('errcaptcha', error);
    }
  };

  useEffect(() => {
    if (scrollTo) {
      const anchor = document.getElementById(scrollTo)
      if(anchor){
        console.log('anchor', anchor);
        anchor.scrollIntoView({ behavior: "smooth" })
        setScrollTo('')
      }
    }
  }, [scrollTo])
  return(
    <Container>
      <ExchangeWrapper>
        <ExchangeForm
          currency={currency1}
          setCurrency={setCurrency1}
          fieldName={'field_left'}
          title={t("GIVE_AWAY")}
          variables={[{name:'Все'}, {name:'Все'}, {name:'Все'},{name:'Все'}]}
          bodyItems={currencies?.filter((currency:any) => currency?._id !== currency2?._id)}
          formikProps={formikProps}
        />
        {/* <StyledArrows src={Arrows}/> */}
        <ExchangeForm
          currency={currency2}
          setCurrency={setCurrency2}
          fieldName={'field_right'}
          title={t("YOU_RECEIVE")}
          variables={[{name:'Все'}, {name:'Все'}, {name:'Все'},{name:'Все'}]}
          bodyItems={currencies?.filter((currency:any) => currency?._id !== currency1?._id)}
          formikProps={formikProps}
          blockField
        />
        <ExchangeOrderForm>
          {/* <Typography variant='h3'></Typography> */}
          <Title>
            {t("RECEIVER_DATA")}
          </Title>
          <TopInputForm>
            <Input
              isDark
              customStyles={{color:'#FFF'}}
              fieldName={'email'}
              title={t("EMAIL")}
              {...formikProps}
            />
            <Input
              isDark
              customStyles={{color:'#FFF'}}
              fieldName={'fio'}
              title={t("NAME")}
              {...formikProps}
            />
            <div style={{
              display:'flex',
              alignItems:'center',
              position:'relative',
              width:'100%',
            }}>
              {currency2 && 
                <PlaceHoldeImage 
                  src={handleImageUrl(currency2?.icon)}
                />
              }
              <Input
                disableAutoComplete
                isDark
                customStyles={
                  currency2 ?
                    {color:'#FFF', paddingLeft:'60px'}
                  :
                    {color:'#FFF'}}
                fieldName={'requisites'}
                placeholder={`${t("ADDRESS")} ${currency2?.name || ''} ${t("WALLET")}`}
                title={t("WALLET")}
                {...formikProps}
                
              />
            </div>
          </TopInputForm>
          <TopForm>
            <ConfirmContainer>
              <CaptchaContainer>
                <ReCAPTCHA
                  sitekey="6Le_hjcqAAAAACzdQca9P7JBIOWvnpQ5TdqodhH8"
                  onChange={handleFormSubmit}
                  onExpired={() => setConfirmed(false)}
                />
              </CaptchaContainer>
              <CheckBoxWrapper>
                <Checkbox
                  onChange={() => setChecked(!checked)}
                  checked={checked}
                />
                <CheckBoxLink target="_blank" href={config?.urlToRules}>
                  {t("CONFIRM_RULES_TEXT")}
                </CheckBoxLink>
              </CheckBoxWrapper>
            </ConfirmContainer>
            <div/>
            <Button 
              disabled={!checked || !confirmed}
              title={t("EXCHANGE")}
              loading={loadingOrder}
              customStyles={{width:'518px'}}
              onClick={formikProps.handleSubmit}
            />
          </TopForm>
        </ExchangeOrderForm>
      </ExchangeWrapper>
      <Reserves currencies={currencies}/>
      <TrustBlock/>
      <Partners/>
      <Reviews/>
      <Contacts/>
      <Faq/>
    </Container>
  )
}

export default HomePage