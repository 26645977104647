import styled from 'styled-components'

export const SubTitleWrapper = styled.div`
  display:flex;
  justify-content: center;
  width:100%;
  align-items: center;
`
export const Nav = styled.div`
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 27px;
  color:black;
  &:hover {
    cursor: pointer;
    color:#1F5EFA;
  }
  @media screen and (max-width: 700px){
    font-size: 14px;
  }
`

export const StyledBox = styled.div`
  display:flex;
  flex-direction: column;
  width: 516px;
  box-shadow: 24;
  box-sizing:border-box;
  border-radius:50px;
  gap:24px;
  padding: 48px;
  @media screen and (max-width: 700px){
    width:80vw;
    padding: 18px;
  }
`