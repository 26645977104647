import React, { FC } from 'react';
import styled, { keyframes } from 'styled-components';
import { ReactComponent as CryptoIcon } from './crypto-ic.svg';

interface SpinnerProps {
  size?: string;
  color?: string;
  cryptoIconColor?: string;
}

const rotateRing = keyframes`
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
`;

const rotateIcon = keyframes`
  0% {
    transform: rotateX(0deg) rotateY(0deg);
  }
  100% {
    transform: rotateX(360deg) rotateY(360deg);
  }
`;

const SpinnerContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
`;

const SpinnerRing = styled.div<SpinnerProps>`
  display: inline-block;
  position: relative;
  width: ${props => props.size || '60px'};
  height: ${props => props.size || '60px'};
  animation: ${rotateRing} 1.5s linear infinite;
`;

const CryptoIconWrapper = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 50%;
  height: 50%;
  perspective: 1000px;
`;

const CryptoIconInner = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  transform-style: preserve-3d;
  animation: ${rotateIcon} 2s linear infinite;
`;

const CryptoIconImg = styled(CryptoIcon)`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  transform-style: preserve-3d;
  transform: rotateX(20deg) rotateY(-45deg);
  path {
  }
`;

const SpinnerLoader: FC<SpinnerProps> = ({ size, color, cryptoIconColor }) => {
  return (
    <SpinnerContainer>
      <SpinnerRing size={size} color={color}>
        <CryptoIconWrapper>
          <CryptoIconInner>
            <CryptoIconImg  />
          </CryptoIconInner>
        </CryptoIconWrapper>
      </SpinnerRing>
    </SpinnerContainer>
  );
};

export default SpinnerLoader;
