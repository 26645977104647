import apiClient from './apiClient'
export type SignInProps = {
  email:string,
  password:string,
}
export type SignUpProps = {
  email:string,
  password:string,
  name:string,
}
// export type ResetPasswordTokenProps = {
//   token:string,
//   id:string
// }
// export type ResetPasswordProps = {
//   password:string,
//   id:string
// }
const authService = {
  signIn: (data:SignInProps) => apiClient.post('auth/signin', data),
  signUp: (data:SignUpProps) => apiClient.post('auth/signup', data),
  // verifyToken: () => apiClient.post('auth/token-verify'),
  getUser: () => apiClient.get('auth/user'),
  updateUser: (data:any, config:any) => apiClient.patch(`auth`, data, config),
  // updateUser: (data:any, config:any) => apiClient.patch(`auth`, data, config),
  // resetPasswordEmail: (email:string) => apiClient.post('auth/reset-password-email', {email}),
  // resetPasswordToken: (data:ResetPasswordTokenProps) => apiClient.post('auth/reset-password-token', data),
  // resetPassword: (data:ResetPasswordProps) => apiClient.patch('auth/reset-new-password', data),
}
export default authService