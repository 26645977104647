import styled from "styled-components";


export const Container = styled.div`
  /* height: 682px; */
  width: 100%;
  box-sizing: border-box;
  padding:80px 90px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  /* justify-content: space-between; */
  gap:150px;
  justify-content: center;
  align-items: flex-end;
  box-sizing: border-box;
  @media screen and (max-width: 700px){
    padding:5px 30px 5px 30px;
    gap:30px;
    justify-content: center;

  }
`

export const LeftSide = styled.div`
  width:100%;
  height:100%;
  display: flex;
  gap:20px;
  justify-content: space-between;
  align-items: center;
  @media screen and (max-width: 700px){
    width: 100%;
    justify-content: center;
    flex-direction: column;
    gap:0px;
  }
  
`

export const RightSideImage = styled.img`
  width: 404px;
  z-index: 999;
  position: absolute;
  bottom:0px;
  right:50px;
  
`
export const RightSideImageBack = styled.img`
  width:100%;
  height:100%;
  width: 544px;
  height: 616px;
  position: absolute;
  bottom:-46px;
  right:0px;
`
export const Title = styled.h2`
  font-style: normal;
  font-weight: 500;
  font-family: Manrope;
  margin: 0px;
  font-size: 64px;
  line-height: 96px;
  color: #000;
  @media screen and (max-width: 700px){
    font-size: 34px;
    width: 100%;
    padding: 0px;
    margin: 0px;
    line-height:60px;
  }
`
export const SubTitle = styled.h4`
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 27px;
  color: #6B6B6B;
  margin: 0px;
  width: 526px;
  word-wrap: break-word;
  @media screen and (max-width: 700px){
    font-size: 14px;
    width: 100%;
  }
`
export const ContactLineWrapper = styled.div`
  display: flex;
  align-items: center;
  width: 486px;
  height: 35px;
  color: #000;
  border-bottom: 1px solid #000;
  margin-bottom:32px;
  @media screen and (max-width: 700px){
    width: 100%;
  }
`
export const ContactLine = styled.a`
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 27px;
  color: #000;
  word-wrap: break-word;
  text-decoration: none;
  &:hover{
    cursor: pointer;
  }
  @media screen and (max-width: 700px){
    font-size: 14px;
  }
`
export const FormWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 460px;
  /* gap:8px; */
  gap:10px;
  position: relative;
  /* @media screen and (max-width: 1280px){
    display: none;
  } */
`
export const ContactIcon = styled.img`
  margin-right:16px;
  
`
