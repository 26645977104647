import React, { useEffect, useRef, useState } from 'react';
import { addingApiService, authService, configService } from '../api';
import { setClientToken } from '../api/apiClient';
import { useLocalStorage } from '../hooks/useLocalStorage';
// import { authService, notificationService, setClientToken } from '../api';
interface AuthContextInterface {
  user:any,
  isAdmin:boolean,
  loadingUser:boolean,
  updateUser:() => void,
  setUser:(clear:null) => void,
  lang:any,
  setLang:(lang:any) => void,
  config:any,
  messageId:any,
  // notifications:any,
  // setAvailableNotifications:(arr:any[]) => void,
  // setAvailableRoles:(arr:any[]) => void,
  // availableNotifications:any[],
  // availableRoles:any[],
  // updateUser:() => void,
  // setClientToInvite:(client:any) => void,
  // clientForInvite:any,
}
type UserType = {
  _id:string,
  email:string,
  image:string,
  name:string,
}
const AuthContext = React.createContext<Partial<AuthContextInterface>>({});

const AuthProvider = (props:any) => {
  const {
    setDataToLs,
    getDataFromLs,
    removeItemFromLs,
  } = useLocalStorage()
  const [user, setUser] = useState<any | null>(null)
  const [isAdmin, setIsAdmin] = useState<boolean>(false)
  const [loadingUser, setLoadingUser] = useState<boolean>(true)
  const [lang, setLang] = useState<any>(localStorage.getItem('lang_bestchange') === 'ru' || localStorage.getItem('lang_bestchange') === 'en' ? 
  localStorage.getItem('lang_bestchange') : 'ru')
  const [config, setConfig] = useState<any>()
  const [messageId, setMessageId] = useState<any>()

  const updateUser = async () => {
    try{
      const res = await configService.get()
      setConfig(res.data)
    } catch (err) {
      console.log('error getting config', err);
    }
    
    // setLoadingUser(true)
    if(getDataFromLs({key:'authToken'})){
      try{
        await setClientToken(getDataFromLs({key:'authToken'}))
        const res = await authService.getUser()
          if(res.data.superUser){
            setIsAdmin(true)
          }
          await setLoadingUser(false)
          await setUser(res.data)
          setMessageId(res.data.userChat)
          await setDataToLs([
            'chatId',
            res.data.userChat,
          ])

      } catch(e:any) {
        setUser(null)
        setLoadingUser(false)
        console.log('errGetUser', e?.response)
      }
    } else {
      setLoadingUser(false)
      setUser(null)
    }
      
  }
  useEffect(() => {
    updateUser()
  },[])
  return (
    <AuthContext.Provider
      value={{
        user,
        updateUser,
        loadingUser,
        setUser,
        lang,
        setLang,
        config,
        messageId
      }}
      {...props}
    />
  );
};

const useAuth = () => {
  const context = React.useContext(AuthContext);
  if (context === undefined) {
    throw new Error('useAuth must be used within a AuthProvider');
  }
  return context as AuthContextInterface;
};

export { AuthProvider, useAuth };
