import { Typography } from "@mui/material";
import { FC, useEffect, useState } from "react";
// import { articleService, chapterService } from "../../Api";
import {
  Container,
  ArticlesWrapper,
  ArticlesBox
} from './styles'
import {BackArrow, Loader} from '../../components'
import ArticleCardComponent from "./articleCard";
import Pagination from '@mui/material/Pagination';
import { useQueryParam, NumberParam } from 'use-query-params';
import { blogService } from "../../api";

const Articles:FC = () => {
  
  const [articles, setArticles] = useState<any[]>([])
  const [loading, setLoading] = useState<boolean>()
  const [pageData, setPageData] = useState<any>({})
  const [pageQuery, setPageQuery] = useQueryParam('page', NumberParam);
  const [page, setPage] = useState<number>(1)

  useEffect(() => {
    setLoading(true)
    blogService.getAllBlog(page)
    .then((res) => {
      setPageData({
        hasNextPage:res.data.hasNextPage,
        hasPrevPage:res.data.hasPrevPage,
        limit:res.data.limit,
        nextPage:res.data.nextPage,
        page:res.data.page,
        pagingCounter:res.data.pagingCounter,
        prevPage:res.data.prevPage,
        totalDocs:res.data.totalDocs,
        totalPages:res.data.totalPages,
      })
      setArticles(res.data.docs)
      setLoading(false)
    })
    .catch((e) => {
      setLoading(false)
    })

    
  },[])
  const handleChangePage = (event: React.ChangeEvent<unknown>, page: number) => {
    setPageQuery(page)
    setPage(page)
  };
  if(loading) return <Container style={{justifyContent: 'center', alignItems:'center'}}><Loader/></Container>
  return (
    <ArticlesBox>
      {/* <BackArrow/> */}
      <Container>
        <Typography  variant='h2'>Blog</Typography>
        <ArticlesWrapper>
          {articles?.map((item, index) => (
            <ArticleCardComponent index={index} item={item}/>
          ))}
        </ArticlesWrapper>
      </Container>
      {pageData.totalPages > 1 ?
          <Pagination
            page={pageData.page}
            count={pageData.totalPages}
            showFirstButton={pageData.hasPrevPage}
            showLastButton={pageData.hasNextPage}
            onChange={handleChangePage}
          />
        :
          null
        }
    </ArticlesBox>
  );
}

export default Articles;