import styled from "styled-components";
type ArrowProps ={ 
  hover?:boolean
}
export const Container = styled.button`
  width:100%;
  display:flex;
  justify-content: center;
  align-items: center;
  font-style: normal;
  color:#FFFF;
  font-weight: 500;
  font-size: 18px;
  background-color: #1F5EFA;
  line-height: 27px;
  height: 64px;
  border:none;
  border-radius: 16px;
  outline: none;
  font-family: Manrope;
  text-transform:uppercase;
  &:hover:enabled{
    cursor: pointer;
    background-color: #FFFF;
    color:#1F5EFA;
    border:1px solid #1F5EFA;
  }
  &:active:enabled{
      /*your styles*/
  }
  &:disabled{
    background-color: grey;
  }
  @media screen and (max-width: 700px){
    font-weight: 500;
    font-size: 14px;
    height:56px;
  }
`

export const ArrowStyle = styled.img<ArrowProps>`
  transform: ${({hover}) => hover ? 'translate(24px)' : 'translate(14px)'};
  @media screen and (min-width: 700px){
    max-width: 15px;
    max-height: 15px;
    margin-right: 15px;
  }
`