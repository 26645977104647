import { FC, useRef, useContext, useState, useEffect } from "react";
import { useAuth } from "../../context/auth.context";
import { resetClientToken } from "../../api/apiClient";
import { BackArrow, Button, Input, Loader, StyledAccordion } from "../../components";
import {
  Container,
  ProfileWrapper,
  ProfileImage,
  ButtonWrapper,
  Label,
  AvatarInput,
  ProfileImageEdit,
  OrderWrapper,
  ProductWrapper,
  Box,
  ProfileButtonsWrapper,
  ProfileWrapperMobile,
  InputContainer
} from './styles'
import { useFormik } from 'formik';
import UserSchema from './user.schema'
import Camera from './photo.svg'
import { authService } from "../../api";
import orderServise from "../../api/orderService";
import { Typography, Avatar } from "@mui/material";
import User from './user.svg'
import handleImageUrl from "../../hooks/useImageUrl";
import { useNavigate } from "react-router-dom";
import {
  Rectangle,
  InfoBlockItem
} from '../OrderPage/styles'
import {
  BodyItem,
  ItemImage,
  ItemText,
} from '../Home/exchange.styles';
import { ReactSVG } from "react-svg";
import { useTranslation } from "react-i18next";
import { ToastContainer, toast } from 'react-toastify';
import { toFormData } from "../../utils/toFormData";
import { useLocalStorage } from "../../hooks/useLocalStorage";



const UserProfile:FC = () => {
  const {user, lang, updateUser} = useAuth()
  const [t] = useTranslation()
  const {getDataFromLs, removeItemFromLs} = useLocalStorage()
  // const { setAlert } = useContext(UIContext);
  const [initialValues, setInitialValues] = useState<any>({
    name: user?.name,
    email: user?.email,
    password: ''
  });
  const navigate = useNavigate()
  const [loading, setLoading] = useState<boolean>(false)
  const [isEdit, setIsEdit] = useState<boolean>(false)
  const [avat, setAvat] = useState(null)
  const [imgPrev, setImgPrev] = useState<any>('')
  const [orders, setOrders] = useState<any[]>([])
  // const router = useRouter()
  useEffect(() => {
    console.log('orderServise')
    setInitialValues({
      name: user?.name,
      email: user?.email,
      password: ''
    })
    setFieldValue('password', '')
    orderServise.getOrderForUser()
    .then((res:any) => {
      setOrders(res.data)
    })
    .catch((e:any) => {
      console.log('error', e)
      // setAlert({
      //   show: true,
      //   severity: 'error',
      //   message:e,
      // });
    })
  },[user])

  const _handleImageChange = (e:any) => {
    if(e.target.files[0].type !== 'image/png' && e.target.files[0].type !== 'image/jpeg'){
      toast.error('Type image must be png or jpeg', {
        position: "bottom-right",
        autoClose: 5000,
        // hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
      return
    }
    if(e.target.files[0].size >  5000000 ){
      toast.error('Image size must be less than 5mb', {
        position: "bottom-right",
        autoClose: 5000,
        // hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
      return
    }
    e.preventDefault();
    const reader = new FileReader();
    const file = e.target.files[0]
    reader.onloadend = () => {
      setAvat(file)
      setImgPrev(reader.result)
    }
    reader.readAsDataURL(file)
  }
  
  
  const Submit = async (data: any) => {
      const getToken = () => {
        return typeof window !== "undefined" ? getDataFromLs({key:'authToken'}) : ''
      }
      const configWithoutImage = {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${getToken()}`,
        },
      }
    
      const config = {
        headers: {
          'Content-Type': 'multipart/form-data',
          'Authorization': `Bearer ${getToken()}`,
        },
      }
    
    setLoading(true)
    const profile = await {
      name: data.name,
      email: data.email,
      password: data.password,
      image: avat
    }
    const profileWithoutAvatar = await {
      name: data.name,
      email: data.email,
      password: data.password,
    }
    if(imgPrev){
      await authService.updateUser(toFormData(profile), config)
      .then(res => {

        updateUser()
        setLoading(false)
        setIsEdit(false)
      })
      .catch((e:string) => {
        console.log('error', e)
        toast.error(e, {
          position: "bottom-right",
          autoClose: 5000,
          // hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
        setLoading(false)
      })

    } else {
      await authService.updateUser(profileWithoutAvatar, configWithoutImage)
      .then(res => {

        updateUser()
        setLoading(false)
        setIsEdit(false)
      })
      .catch((e:string) => {
        console.log('error', e)
        toast.error(e, {
          position: "bottom-right",
          autoClose: 5000,
          // hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
        setLoading(false)
      })
    }
  };
 const {
    values,
    errors,
    touched,
    isSubmitting,
    handleSubmit,
    handleChange,
    setFieldValue,
    resetForm,
  } = useFormik({
    enableReinitialize: true,
    validationSchema: UserSchema,
    initialValues:initialValues,
    onSubmit: (value) => Submit(value),
  });
  const formikProps = {
    errors,
    values,
    touched,
    handleChange,
  };

  if(loading) return <Container style={{justifyContent:'center', alignItems:'center'}}><Loader/></Container>
  return (
    <Box>
      <BackArrow/>
      <Container>
        <ProfileWrapper>
          <div style={{display:'flex', alignItems:'flex-start'}}>
            {isEdit ?
                <Label htmlFor='file-input' >
                  <ProfileImageEdit src={Camera}/>
                  <ProfileImage 
                    src={`${imgPrev ? imgPrev : handleImageUrl(user?.image) ? handleImageUrl(user?.image) : User}`}
                  />
                  <AvatarInput
                    type="file"
                    accept="image/png, image/jpeg"
                    id="file-input"
                    onChange={(e) => _handleImageChange(e)}
                  />
                </Label>
            :
              <ProfileImage 
                src={user?.image ? handleImageUrl(user?.image) : User}
              />
            }
            <ProfileButtonsWrapper>
              <ButtonWrapper>
                <Button title={isEdit ? t('CANCEL_EDIT') : t("EDIT")} onClick={() => setIsEdit(!isEdit)}/>
              </ButtonWrapper>
              <ButtonWrapper>
                <Button title={t("LOGOUT")} onClick={() => {
                  resetClientToken()
                  removeItemFromLs('authToken')
                  if(typeof window !== 'undefined'){
                    window.location.reload();
                  }
                }}/>
              </ButtonWrapper>
            </ProfileButtonsWrapper>
          </div>
          <InputContainer>
            <Input
              fieldName={'email'}
              title={t("EMAIL")}
              customStyles={{color:'#000'}}
              {...formikProps}
              isBlocked={!isEdit}
            />
          </InputContainer>
          <InputContainer>
            <Input
              fieldName={'name'}
              title={t("NAME")}
              customStyles={{color:'#000'}}
              {...formikProps}
              isBlocked={!isEdit}
            />

          </InputContainer>
          <InputContainer>
            <Input
              fieldName={'password'}
              title={t("PASSWORD")}
              customStyles={{color:'#000'}}
              {...formikProps}
              isBlocked={!isEdit}
              isPassword={true}
            />
          </InputContainer>
          {isEdit ?
            <Button title={t("SAVE")} onClick={() =>  {
              if(imgPrev || user.email !== values.email || user.name !== values.name || user?.password !== ''){
                handleSubmit()
              } else {
                setIsEdit(!isEdit)
              }
            }}/>
          :
            <div style={{minHeight:'59px'}}></div>
          }
        </ProfileWrapper>
        <ProfileWrapperMobile>
          <div style={{
            display:'flex',
            flexDirection:'column',
            width:'100%',
            gap:'10px'
          }}>
            {orders?.length > 0 ?
              orders.map((item:any) => (
                <div>
                <StyledAccordion  key={item._id} item={{
                  title: `${new Date(item.createdOn).toISOString().split('T')[0]}`,
                  _id:item._id,
                }}>
                  <div style={{
                    marginBottom:'20px',
                    display:'flex',
                    gap:'10px',
                    border:'1px solid black',
                    borderRadius:'4px',
                    padding:'10px',
                    boxSizing:'border-box',

                  }}>
                    <Typography 
                      sx={{color: '#FFF',}}
                      variant='h4'>
                      {t("STATUS")}: 
                      
                    </Typography>
                    <Typography 
                      sx={{color: '#FFF',}}
                      variant='h4'>
                      {item?.status?.title?.[lang]}
                    </Typography>
                  </div>
                  <div style={{display:'flex', gap:'25px', alignItems:"center", width:'100%'}}>
                    <InfoBlockItem>
                      <Typography sx={{color: '#FFF',}} variant='h5'>{t("FROM")}:</Typography>
                      <BodyItem style={{flexDirection:'row'}}>
                        {item.from.icon.indexOf('svg') !== -1 ?
                          <ReactSVG src={handleImageUrl(item.from.icon)} style={{width: '25px', height:'25px'}}  />
                        :
                          <ItemImage src={handleImageUrl(item.from.icon)}/>
                        }
                        <ItemText style={{color: '#FFF',}}>
                          {item.from.name||''}
                        </ItemText>
                      </BodyItem>
                    </InfoBlockItem>
                    <InfoBlockItem>
                      <Typography sx={{color: '#FFF',}} variant='h5'>{t("TO")}:</Typography>
                      <BodyItem style={{flexDirection:'row'}}>
                        {item.to.icon.indexOf('svg') !== -1 ?
                          <ReactSVG src={handleImageUrl(item.to.icon)} style={{width: '25px', height:'25px'}}  />
                        :
                          <ItemImage src={handleImageUrl(item.to.icon)}/>
                        }
                        <ItemText style={{color: '#FFF',}}>
                          {item.to.name||''}
                        </ItemText>
                      </BodyItem>
                    </InfoBlockItem>
                  </div>
                </StyledAccordion>
                </div>
              ))
            :
            <div style={{display:'flex', justifyContent:'center', alignItems:'center'}}>
              <Typography variant='h3'>{t("NO_ORDERS_YET")}</Typography>
              
            </div>
          }
          </div>
        </ProfileWrapperMobile>
      </Container>
    </Box>
  );
}

export default UserProfile;