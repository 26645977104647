import styled from 'styled-components'

export const SubTitleWrapper = styled.div`
  display:flex;
  justify-content: space-between;
  width:100%;
  box-sizing: border-box;
  @media screen and (max-width: 700px){
    margin-top:10px;
  }
`

type NavProps = {
  isGrey?:boolean;
}
export const Nav = styled.div<NavProps>`
  font-family:Manrope;
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 27px;
  color:black;
  &:hover {
    color:${({isGrey}) => isGrey ? '' : '#1F5EFA'};
    cursor: pointer;
  }
  @media screen and (max-width: 700px){
    font-size: 14px;
    line-height: 17px;
  }
`
export const TermsWrapper = styled.div`
  font-size:14px;
  margin-top:20px;
  @media screen and (max-width: 700px){
    margin-top:10px;
    font-size:12px;

  }
`
export const Terms = styled.span`
  color:rgba(1, 1, 122, 1);
   &:hover {
    cursor: pointer;
  }
  
`

export const StyledBox = styled.div`
  display: flex;
  flex-direction: column;
  background-color: white;
  width: 516px;
  box-shadow: 24;
  box-sizing:border-box;
  border-radius:50px;
  gap:24px;
  padding: 48px;
  @media screen and (max-width: 700px){
    width:80vw;
    padding: 18px;
  }
`