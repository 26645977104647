import React, {FC, useState, useEffect, useRef} from 'react';
import VisibilityIcon from './eye.svg';
import VisibilityOffIcon from './eye-slash.svg';
import IconButton from '@mui/material/IconButton';
import {
  Container,
  InputContainer,
  ErrorText,
  Title,
  SecurePasswordBox,
  InputContainerTextarea
} from './styles'
type InputProps = {
  values?:any,
  value?:any,
  handleChange?:any,
  touched?:any,
  errors?:any,
  fieldName?:any,
  isPassword?:boolean,
  title?:string | any,
  isChat?:boolean,
  isBlocked?:boolean,
  callbackRefFocus?:() => void,
  isReview?:boolean,
  customStyles?:any,
  isExchangeField?:any,
  readonly?:boolean,
  placeholder?:any
  handleBlur?:any
  isDark?:boolean,
  disableAutoComplete?:boolean,
}
const Input:FC<InputProps> = (props) => {
  const {
    values,
    handleChange,
    touched,
    errors,
    fieldName,
    isPassword,
    title,
    isChat,
    isBlocked,
    callbackRefFocus,
    isReview,
    customStyles,
    isExchangeField,
    value,
    placeholder,
    handleBlur,
    isDark,
    disableAutoComplete
  } = props
  const fieldValue = values?.[fieldName];
  const fieldError = errors?.[fieldName];
  const fieldTouched = touched?.[fieldName];
  const [focus, setIsFocus] = useState<boolean>(false)
  const [privatePassword, setPrivatePassword] = useState<boolean>(false);
  const input:any = useRef()
  const handleClickShowPassword = () => {
    setPrivatePassword(!privatePassword);
  };

  const handleMouseDownPassword = (
    event: React.MouseEvent<HTMLButtonElement>,
  ) => {
    event.preventDefault();
  }; 
  
  useEffect(() => {
    const handleFocus = () => {
      if (input.current && callbackRefFocus) {
        callbackRefFocus()
      }
    }
    document.addEventListener('focus', handleFocus, true);
    return () => {
      document.removeEventListener('focus', handleFocus, true);
    };
  },[]);

  
  return(
    <Container>
      {/* {focus || fieldValue ?
        <Title htmlFor='inputText'>
          {title}
        </Title>
      : null} */}
      {isReview ? 
        <InputContainerTextarea
          ref={input}
          id='inputText'
          isChat={isChat}
          onFocus={() => setIsFocus(true)}
          onBlur={() => setIsFocus(false)}
          placeholder={placeholder ? placeholder : title}
          readOnly={isBlocked || false}
          name={fieldName}
          value={fieldValue}
          onChange={handleChange}
          style={customStyles}
        />
      :
        <InputContainer
          style={customStyles}
          ref={input}
          // id='inputText'
          isChat={isChat}
          //@ts-ignore
          // autocomplete={disableAutoComplete ? 'off' : 'on'}
          onFocus={() => setIsFocus(true)}
          onBlur={(e) => {
            if(handleBlur){
              handleBlur(e)
            }
            setIsFocus(false)
          }}
          placeholder={placeholder ? placeholder : title}
          readOnly={isBlocked || false}
          name={fieldName}
          type={isExchangeField ? 'number' : isPassword ? privatePassword ? 'text' : 'password' : 'text'}
          value={fieldValue || value}
          onChange={handleChange}
          isDark={isDark}
        />
      }
      {isPassword ?
        <SecurePasswordBox >
          <IconButton
            aria-label="toggle password visibility"
            onClick={handleClickShowPassword}
            onMouseDown={handleMouseDownPassword}
            edge="end"
          >
            {privatePassword ? (
              <img src={VisibilityOffIcon} />
            ) : (
              <img src={VisibilityIcon} />
            )}
          </IconButton>
        </SecurePasswordBox>
      : null
      }
      <ErrorText style={{minHeight:'0px'}}>
        {isExchangeField ? fieldError : (fieldTouched && fieldError)}
      </ErrorText>
    </Container>
  )
}

export default Input