import React, { FC } from 'react'
import { styled } from '@mui/material/styles';
import MuiAccordion, { AccordionProps } from '@mui/material/Accordion';
import MuiAccordionSummary, {
  AccordionSummaryProps,
} from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import Plus from './plus.svg'
import { Typography } from '@mui/material';
import Arrow from './arrow.svg'
const Accordion = styled((props: AccordionProps) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  // border: '1px solid rgba(0, 0, 0, .125)',
  backgroundColor: 'transparent',
  borderRadius:'8px',
  width: '100%',
  '&:not(:last-child)': {
  },
  '&:before': {
    display: 'none',
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: '8px 0px',
  color:'#FFF',
}));

type StyledAccordionProps = {
  item:any,
  children:any,
}

const StyledAccordion:FC<StyledAccordionProps> = (props) => {
  const {item, children} = props
  const [expanded, setExpanded] = React.useState<string | false>('panel1');

  const handleChange =
    (panel: string) => (event: React.SyntheticEvent, newExpanded: boolean) => {
      setExpanded(newExpanded ? panel : false);
    };
  const AccordionSummary = styled((props: AccordionSummaryProps) => (
    <MuiAccordionSummary
      expandIcon={<img style={{width:'24px', height:'24px'}} src={Arrow} />}
      {...props}
    />
  ))(() => ({
    // display: 'flex',
    padding:'0px',
    // justifyContent:'space-between',
    // transition:'all 0.3s ease-in-out',
    borderBottom: '1.5px solid #6B6B6B',
    '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
      transform: 'rotate(-180deg)',
      borderBottom: 'none',
    },
    '& .MuiAccordionSummary-content': {
      borderBottom: 'none',
    },
    
  }));
  
  return (
    <Accordion  expanded={expanded === item._id} onChange={handleChange(item._id)}>
      <AccordionSummary aria-controls="panel1d-content" id={item._id}>
        <Typography sx={{color:'#FFF', marginRight:'10px'}} variant="h4">{item.title}</Typography>
      </AccordionSummary>
      <AccordionDetails>
        {children}
      </AccordionDetails>
    </Accordion>
  )
}

export default StyledAccordion