import styled, { keyframes } from 'styled-components';


import SVG from "react-inlinesvg";

export const ExchangeContainer = styled.div`
  /* width: 564px; */
  padding: 10px 32px;
  box-sizing: border-box;
  display: flex; 
  flex-grow: 0;
  flex:auto 1;
  width:500px;
  flex-direction: column;
  @media screen and (max-width: 700px){
    padding: 10px;
    width:100%;
  }
  /* flex: 1 auto; */
`
export const TitleWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  gap:50px;
`
export const Title = styled.h4`
  font-size: 28px;
  font-weight: 500;
  color: #FFF;
  @media screen and (max-width: 700px){
    font-size: 18px;
    font-weight: 600;
    margin-bottom:0px;
  }
`

export const Variables = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  /* justify-content: flex-start; */
  gap:10px;
  width: 100%;
`
export const VariableItem = styled.div`
  display: flex;
  border-radius: 8px;
  padding: 5px 10px;
  box-shadow: 2px;
  /* flex:auto 1 ; */
  border: 1px solid black;
  &:hover{
    cursor: pointer;
  }
`

export const BodyWrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap:26px;
  box-sizing: border-box;
  justify-content: center;
  max-height:400px;
  border-radius: 26px;
  padding: 28px;
  background-color: #FFF;
  overflow-y: auto;
  position: relative;
  width: 100%;
  /* ::after {
    content: "";
    position: fixed;
    left: 0;
    right: 0;
    height: 20px;
    pointer-events: none;
    bottom: 38px;
    z-index:20;
    background:linear-gradient(to top, rgba(255, 255, 255, 1), rgba(255, 255, 255, 0.1));
    
  }
  ::before {
    content: "";
    position: fixed;
    left: 0;
    right: 0;
    height: 20px;
    pointer-events: none;
    top: 50px;
    z-index:20;
    background:linear-gradient(to bottom, rgba(255, 255, 255, 1), rgba(255, 255, 255, 0.1));
    
  } */
  ::-webkit-scrollbar {
    width: 8px;
  }
  ::-webkit-scrollbar-thumb {
    box-shadow: inset 0 0 2px 2px #DBDBDB;
    border-width: 0 2px 0 2px;
    border-color: rgba(28,28,28,0);
    border-style: solid;
    border-radius: 5px;
  }
  ::-webkit-scrollbar-track {
    margin: 12px 0 12px 0;
  }
  ::-webkit-scrollbar-button{
    display: none;
  }
  @media screen and (max-width: 700px){
    display: none;
  }
`
type Active = {
  active?:boolean;
  isBlocked?:boolean
}
export const BodyItem = styled.div<Active>`
  display: flex;
  flex-direction: column;
  /* justify-content: center; */
  box-sizing: border-box;
  /* box-shadow: ${({active}) => active ? 'inset 0 0 10px rgba(0, 0, 0, 0.5)' : ''}; */
  align-items: center;
  gap: 10px;
  opacity: ${({isBlocked}) => isBlocked ? 0.3 : 1};
  position: relative;
  /* flex-grow:1; */
`
export const SoonText = styled.div`
  font-size:12px;
  position: absolute;
  top:30%;
  z-index:3;
  box-shadow: 10px 10px 10px rgba(0, 0, 0, 0.1);
  box-sizing: border-box;
  /* border: 1px solid #1078D9; */
  background-color: rgba(255, 255, 255, 0.8);
  border-radius:8px;
  padding: 12px 4px;
  color:#2E2790;
  &:hover{
    cursor: pointer;
  }

`
export const ItemText = styled.div`
  font-weight: 400;
  font-size: 20px;
  color: #000000;
`


 
export const ItemImage = styled.img<Active>`
  width: 70px;
  height: 70px;
  display: flex;
  flex-direction: column;
  box-shadow: ${({active}) => active ? ' 0 0 10px rgba(0, 0, 0, 0.2)' : ''};
  border:${({active}) => active ? `1px solid #1078D9` : ''};
  /* transition:box-shadow 0.5s ease-in-out; */
  padding: 10px;
  border-radius: 4px;
  /* box-sizing: border-box; */
  box-sizing: border-box;
  background-color: #ffffff;
  border-radius: 12px;
  &:hover{
    cursor: pointer;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  }
  @media screen and (max-width: 700px){
    width: 40px;
    height: 40px;
  }
`
export const ItemImageSelect = styled.img<Active>`
  width: 50px;
  height: 50px;
  display: flex;
  flex-direction: column;
  box-shadow: ${({active}) => active ? ' 0 0 10px rgba(0, 0, 0, 0.2)' : ''};
  border:${({active}) => active ? `1px solid #1078D9` : ''};
  /* transition:box-shadow 0.5s ease-in-out; */
  padding: 10px;
  border-radius: 4px;
  /* box-sizing: border-box; */
  box-sizing: border-box;
  /* background-color: #ffffff; */
  border-radius: 12px;
  /* &:hover{
    cursor: pointer;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  } */
  @media screen and (max-width: 700px){
    width: 40px;
    height: 40px;
  }
`

export const ItemImageWrapper = styled(SVG)`
  width: 50px;
  height: 50px;
  display: flex;
  flex-direction: column;
  padding: 10px;
  box-sizing: border-box;
  border-radius: 4px;
  /* background-color: #ffffff; */
  box-shadow: 0px 4px 8px rgb(0 0 0 / 25%), inset 0px 0px 0px rgb(0 0 0 / 0%);
  border-radius: 12px;
`

export const InputWrapper = styled.div`
  display: flex;
  gap: 10px;
  align-items: flex-start;
  
  width: 100%;
  margin-top: 40px;
  @media screen and (max-width: 700px){
    margin-top: 20px;
    align-items: center;
    flex-wrap:wrap;
  }
  
`