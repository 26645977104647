import styled from 'styled-components'


export const ArticleCard = styled.div`
  display: flex;
  /* max-width: 382px; */
  /* min-width: 382px; */
  flex: 1 auto;
  flex-grow:1;
  flex-basis:0;
  min-height: 381px;
  max-height: 381px;
  flex-direction: column;
  justify-content: space-between;
  box-sizing: border-box;
  background-color:#FFF;
  border-radius: 8px;
  padding: 20px;
  margin-bottom:24px;
  &:hover{
    cursor: pointer;
  }
  @media screen and (max-width: 700px){
    margin:0px;
  }
`
export const ArticlesImg = styled.img`
  /* min-width: 300px; */
  margin-right: 10px;
  /* object-fit: cover; */
  object-fit: contain;
  height: 200px;
  @media screen and (max-width: 700px){
    height: 120px;
  }
`
export const ArticleTitle = styled.h3`
  font-family: Poppins;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  text-decoration: none;
  line-height: 27px;
  /* color: #FFF; */
  margin-top:24px;
  margin-bottom: 24px;
`
export const ArticleSubTitle = styled.h4`
  font-family: Poppins;
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 27px;
  color: #B4B4B4;
`
export const DateWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 27px;
`
export const DateIcon = styled.img`
  width: 24px;
  height: 24px;
  margin-right: 10px;
`
export const TitleWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  min-height: 145px;
  justify-content: space-between;
`

export const ContentWrapper = styled.div`
  display: flex;
  min-width:100%;
  flex-direction: column;
`