import styled from "styled-components";
import {InputContainer} from '../Input/styles';

export const Container = styled.div`
  position: relative;
  display:flex;
  justify-content: flex-end;
  gap:10px;
  flex-direction: column;
  box-sizing: border-box;
`
export const InputCustom = styled(InputContainer)`
  
  color:#000 !important;
`
export const LabelTitle = styled.p`
  font-family: Manrope;
  font-style: normal;
  margin-bottom:0px;
  font-weight: 300;
  font-size: 18px;
  color: #6B6B6B;
`