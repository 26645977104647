import { Typography } from '@mui/material'
import React, { FC } from 'react'
import Back from './arrowLeft.svg'
import {Container} from './styles'
import BackTwo from './arrowLine.svg'
import styled from 'styled-components'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
type Props = {
  withoutWord?:boolean
}
const ArrowImg = styled.img`
  width:20px;
  height: 20px;

`
const BackArrow:FC<Props> = ({withoutWord}) => {
  const navigate = useNavigate()
  const [t] = useTranslation()
  return (
    <Container withoutWord={withoutWord} onClick={() => navigate(-1)}>
      <ArrowImg alt='back-arrow' src={Back}/>
      <Typography variant='h4'>{t("BACK")}</Typography>
    </Container>
  )
}

export default BackArrow