import styled from "styled-components";
import SVG from "react-inlinesvg";
type ChatCardProps = {
  isActive?:boolean,
}
export const Container = styled.div`
  width:100%;
  min-height:100%;
  display:flex;
  justify-content: center;
  @media screen and (max-width: 801px){
  justify-content: flex-start;
    flex-direction:column;
  }
`

export const ChatsWrapper = styled.div`
  max-height:calc(100vh - 124px);
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  overflow-y: scroll;
  @media screen and (max-width: 801px){
    width:100%;
  }
`
export const ChatCard = styled.div<ChatCardProps>`
  display: flex;
  align-items: center;
  height:100px;
  padding: 30px;
  background-color: ${({isActive}) => isActive ? 'gray' : null};
  @media screen and (max-width: 801px){
    width:100%;
  }
  &:hover{
    cursor: pointer;
  }
`

export const MessageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-right:40px;
  margin-left: 40px;
`
export const TimeWrapper = styled.div`
  display: flex;
  flex-direction: column;
`


export const ChatDescriptionWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
`
export const ToAutoSizer = styled.div`
  display: flex;
  height:calc(100vh - 94px);
  width:30vw;
  margin-left:13px;
  @media screen and (max-width: 801px){
    width:100%;
    height: calc(100% - 60px);
    margin-left:0px;
  }
`
export const MobileChatWrapper = styled.div`
  &:hover{
    cursor: pointer;
  }
  @media screen and (min-width: 801px){
    display: none;
  }
`
export const DesktopChatWrapper = styled.div`
  @media screen and (max-width: 800px){
    display: none;
  }
`

export const MobileHeader = styled.div`
  display: flex;
  width:100%;
  padding:16px;
  @media screen and (min-width: 800px){
    padding:5px;
  }
`

export const DotImage = styled(SVG)`
  max-width: 10px;
  height: 10px;
`