const ValidationKit = {
  isRequired: () => 'This field can not be blank',
  isInvalidEmail: () => 'Email is invalid',
  minCharacters: (amount = 6) => `Should have at least ${amount} characters`,
  areEqualValues: (key:any) => `${key} do not match`,
  maxLength: (value:any) => `Max length ${value}`,
  isNumbers: () => 'Should be numbers',
};

export default ValidationKit;
