import {
  Container,
  PartnerContainer,
  PartnersWrapper,
  PartnerImage,
  ToPartnersWrapper,
  HelloText,
  HelloTextClients,
  ToPartnersTitleWrapper,
  ToPartnersText,
  PartnerCardContainer,
  PartnerTitle,
} from './styles'
import React, {FC, useEffect, useState} from 'react'
import { Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { partnersService } from '../../../../api'
import handleImageUrl from '../../../../hooks/useImageUrl'
import { useAuth } from '../../../../context/auth.context'
import {Button, StrokeButton} from '../../../../components'


const Partners:FC = () => {
  const [t] = useTranslation()
  const [partners, setPartners] = useState<any[]>([])
  const {config, lang} = useAuth()
  const getPartners = async () => {
    try{
      const res = await partnersService.getPartners()
      setPartners(res.data)
    } catch(err){
      
    } finally {

    }
  }
  useEffect(() => {
    getPartners()
  },[])
  return (
    <Container>
      <Typography sx={{color:'#000'}} variant='h3'>{t("TO_PARTNERS")}</Typography>
      <ToPartnersWrapper>
        <ToPartnersTitleWrapper>
          <HelloText>{t("HELLO_CLIENT_TEXT")}</HelloText>
          <HelloTextClients>{t("HELLO_CLIENT_TEXT_TWO")}</HelloTextClients>
        </ToPartnersTitleWrapper>
        <ToPartnersText>{config?.to_paertners?.[lang]?.message || t("TO_CLIENTS_BODY_TEXT")}</ToPartnersText>
      </ToPartnersWrapper>
      <div style={{display:'flex', width:'100%', justifyContent:'flex-end'}}>
        <HelloTextClients noWidth style={{borderBottom:"1.5px solid #101010"}} >{t("PARTNERS")}</HelloTextClients>
      </div>
      <PartnersWrapper>
        {partners?.length > 0 && partners.map((partner:any) => (
          <PartnerCard partner={partner}/>
          // <PartnerContainer onClick={() => window.open(partner.url)}>
          //   <PartnerImage src={handleImageUrl(partner.icon)}/>
          // </PartnerContainer>
        ))}
      </PartnersWrapper>
    </Container>
  )
}

export default Partners




const PartnerCard:FC<any> = ({partner}) => {
  const [t] = useTranslation()
  return(
    <PartnerCardContainer>
      <PartnerImage src={handleImageUrl(partner.icon)}/>
      <PartnerTitle>
        {partner.title}
      </PartnerTitle>
      <Button
        title={t("CHECK")}
        onClick={() => window.open(partner.url)}
      />
    </PartnerCardContainer>
  )
}