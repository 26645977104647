import  secureLocalStorage  from  "react-secure-storage";

type localStorageHook = {
  key:string,
  data?:any
}
type EditArrProps = {
  action:'edit'|'delete'
  arr?:any[],
  objToSave?:any,
  key:'userReview',
}
export const useLocalStorage = () => {

  const editArr = (props:EditArrProps) => {
    console.log('props',props);
    switch(props.action) {
      case 'edit':
        //@ts-ignore
        let arr = JSON.parse(secureLocalStorage.getItem(props.key))
        if(!arr) {
          console.log('props',props);
          const arrToSave = JSON.stringify([props.objToSave])
          secureLocalStorage.setItem(props.key, arrToSave)
        } else {
          const arrToSave = JSON.stringify([...arr, props.objToSave])
          secureLocalStorage.setItem(props.key, arrToSave)
        }
        return
      case 'delete':
        secureLocalStorage.removeItem(props.key)
        return
    }
    
    

  }
  const setDataToLs = (props:any) => {
    let toSave = JSON.stringify(props[1])
    secureLocalStorage.setItem(props[0], toSave)
  }
  const getDataFromLs = (props:localStorageHook) => {
    //@ts-ignore
    return  JSON.parse(secureLocalStorage.getItem(props.key))
  }
  const removeItemFromLs = (key:string) => {
    secureLocalStorage.removeItem(key)
  }
  return {
    setDataToLs,
    getDataFromLs,
    removeItemFromLs,
    editArr
  }
}