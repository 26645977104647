import styled from "styled-components";

export const Container = styled.div`
  /* width:100%; */
  min-height:100vh;
  display:flex;
  /* margin-top:10px; */
  flex-direction: column;
  padding:40px 120px 40px 120px;
  @media screen and (max-width: 700px){
    padding:22px 22px 22px 22px;
  }
`

export const Paragraph = styled.div`
  display: flex;
`
export const ArticleImage = styled.img`
  width:calc(100vw - 240px);
  object-fit: contain;
  margin-bottom: 40px;
  @media screen and (max-width: 700px){
    width:100%;
  }
`
export const SubTitleWrapper = styled.div`
  margin-top:20px;
  display: flex;
  min-width:100%;
  justify-content: space-between;
`