import React, {FC} from 'react'
// import { MutatingDots } from "react-loader-spinner";


const Loader:FC = () => {
  return (
    // <MutatingDots
    //   height="100"
    //   width="100"
    //   color='#1A1555'
    //   secondaryColor= "#1A1555"
    //   ariaLabel='loading'
    // />
    <>
    </>
  )
}

export default Loader