import styled from 'styled-components'

type Props = {
  withoutWord?:boolean,
}
export const Container = styled.div<Props>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width:${({withoutWord}) => withoutWord ? '30px' : '80px'};
  &:hover{
    cursor:pointer;
  }
  margin-bottom: ${({withoutWord}) => withoutWord ? '' : '30px'};
  margin-right: ${({withoutWord}) => withoutWord ? '10px' : ''};
`