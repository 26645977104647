import styled from "styled-components";

export const Container = styled.div`
  width:100%;
  min-height:100vh;
  display:flex;
  flex-direction: column;
`

export const ArticlesBox = styled.div`
  padding-left:120px;
  margin-top: 30px;
  @media screen and (max-width: 700px){
    padding:16px;
  }
`
export const ArticlesWrapper = styled.div`
  display:flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  gap:20px;
  margin-top:40px;
  width: calc(100vw - 240px);
  @media screen and (max-width: 700px){
    width: 100%;
    /* justify-content: center; */
  }
`

