import apiClient from './apiClient'
export type OrderProps = {
  // list: any[],
  // userinfo: object,
  // user: string,
  // totalPrice:number,
}
const ruleService = {
  getRules:() => apiClient.get(`rules`),
}
export default ruleService