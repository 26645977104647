import styled from 'styled-components'

export const ArticleCard = styled.div`
  display: flex;
  flex-direction: column;
  padding:22px;
  min-width: 451px;
  /* height: 161px; */
  border-radius:4px;
  background: rgba(255, 255, 255, 0.03);
  box-sizing: border-box;
  border: 2px solid rgba(255, 255, 255, 0.2);
  border-radius: 24px;
  margin:0px 20px;
  &:hover{
    cursor: pointer;
  }
  @media screen and (max-width: 700px){
    min-width: 283px;
    max-height: 266px;
    margin-bottom: 20px;
    padding-top: 12px;
  }
`
export const ArticlesImg = styled.img`
  width:150px;
  height: 150px;
  margin-right: 10px;
  object-fit: cover;
  @media screen and (max-width: 700px){
    width: 283px;
    height: 166px;
  }
  
`
export const TitleWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: flex-start;
  justify-content: flex-start;
  
`
export const UserInfoWrapper = styled.div`
  display: flex;
  align-items: flex-start;
  width: 100%;
  justify-content: space-between;
  @media screen and (max-width: 700px){
    flex-direction: column;
    align-items: flex-start;

  }

`
export const DateWrapper = styled.div`
  display: flex;
  /* flex-direction: column; */
  align-items: center;
  gap:10px;
  /* justify-content: space-between; */
  /* width:100%; */
  /* height:65px; */
  @media screen and (max-width: 700px){
    margin-top: 10px;
    width:100%;
  }
`

export const TextComment = styled.div`
  word-break:break-word;
  max-width:500px;
  height:100px;
  margin-top:20px;
  color:#FFF;
  /* white-space: nowrap; */
  overflow: hidden !important;
  text-overflow: ellipsis;
  @media screen and (max-width: 700px){
    max-width:200px;
    height: 70px;
  }
`