import styled from 'styled-components';


export const Container = styled.div`
  display:flex;
  flex-direction: column;
  padding: 20px;
  box-sizing: border-box;
  margin-top: 30px;
`

export const Header = styled.div`
  display:flex;
  flex-direction:row;
  justify-content:center;
  margin-bottom: 50px;
 /* justify-content:space-between; */
`

export const BodyRule = styled.div`
  
`

export const RuleTitle = styled.div`
  text-transform: uppercase;
  color: #FFF;
  font-size:18px;
  margin-bottom: 12px;
`
export const RuleText = styled.div`
  font-size: 16px;
  color: #FFF;

`

export const RuleContainer = styled.div`
  display: flex;
  flex-direction:column;
  gap:34px;
`