import styled from "styled-components";


export const Container = styled.div`
  width: 100%;
  /* margin:40px 20px 40px 20px; */
  box-sizing: border-box;
    background: #FFF;
  display: flex;
  flex-wrap: wrap;
  gap:40px;
  flex-direction: column;
  height: 100%;
`
export const StyledArrows = styled.img`
  fill: #9a9a9a;
  transition: all 0.3s;
  margin-top: 15px;
  margin-bottom: 15px;
  height:18px;
  &:hover{
    fill: #274cff;
    cursor: pointer;
    transform: scaleX(0.8);
  }
`

export const ExchangeWrapper = styled.div`
  display: flex;
  flex-direction: row;
  /* justify-content: space-between; */
  /* border-radius: 8px; */
  flex-wrap: wrap;
  align-items: center;
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  margin-bottom: 40px;
  background-color:#101010;
  padding:20px 50px;
  border-bottom-left-radius: 50px;
  border-bottom-right-radius: 50px;
  @media screen and (max-width: 700px){
    margin-bottom: 10px;
    padding:10px 20px;
    border-bottom-left-radius: 30px;
    border-bottom-right-radius: 30px;
  }
`

export const ExchangeOrderForm = styled.div`
  display: flex;
  flex-direction: column;
  /* justify-content: flex-end; */
  /* align-items: flex-end; */
  width:100%;
  box-sizing: border-box;
  padding: 32px;
  gap: 10px;
  @media screen and (max-width: 700px){
    padding: 12px;
    flex-direction: row;
    flex-wrap:wrap;
    margin-top:20px ;
  }
`

export const TopForm = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  justify-content:space-between;
  background: #FFF;
  box-sizing: border-box;
  padding:20px;
  border-radius: 26px;
  /* flex-direction:row; */
  width:100%;
  @media screen and (max-width: 700px){
    flex-wrap:wrap;
    justify-content:center;
  }
`

export const TopInputForm = styled.div`
  width:100%;
  display: flex;
  align-items:flex-start;
  display: flex;
  gap:10px;
  @media screen and (max-width: 700px){
    width:100%;
    flex-wrap:wrap;
  }
`

export const PlaceHoldeImage = styled.img`
  position: absolute;
  width: 40px;
  height: 40px;
  left:10px;
  top:10px;
  /* transform: translate(-50%, -50%); */
  @media screen and (max-width: 700px){
    width: 25px;
    height: 25px;
    left:10px;
    top:17px;
  }
`

export const Title = styled.h4`
  font-size: 28px;
  font-weight: 500;
  color: #FFF;
  @media screen and (max-width: 700px){
    font-size: 18px;
    font-weight: 600;
    margin-bottom:0px;
  }
`

export const CheckBoxWrapper = styled.div`
  display: flex;
  align-items: center;
  gap:6px;
`

export const CheckBoxLink = styled.a`
  color:#000;
  word-wrap: break-word;
  /* width:100px; */
  word-break: break-word;
  @media screen and (max-width: 700px){
    width:150px;
  }
  @media screen and (max-width: 290px){
    width:100px;
  }
`

export const ConfirmContainer = styled.div`
  display: flex;
  flex-direction: column;
  @media screen and (max-width: 700px){
    align-items: center;
    justify-content: center;

  }
`
export const CaptchaContainer = styled.div`
  @media screen and (max-width: 700px){
    margin-left:70px;
    transform:scale(0.75);
    transform-origin:0 0
  }
  @media screen and (max-width: 280px){
    margin-left:100px;
    transform:scale(0.65);
  }
  @media screen and (max-width: 270px){
    margin-left:130px;
    transform:scale(0.55);
  }
  @media screen and (max-width: 250px){
    margin-left:170px;
    transform:scale(0.45);
  }
`