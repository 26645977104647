import React from 'react';
import { useEffect, useState } from 'react';
import IconButton from '@mui/material/IconButton';
import Copy from '@mui/icons-material/ContentCopy';
import TextField, { TextFieldProps } from '@mui/material/TextField';
import { styled } from '@mui/material/styles';
import InputAdornment from '@mui/material/InputAdornment';
import Tooltip from '@mui/material/Tooltip';
import {Container, LabelTitle} from './styles'
import Input from '../Input';
import CopyIcon from './copy-ic.svg'
import { useTranslation } from 'react-i18next';
export enum CopyError {
  NotAvailable,
  WriteError,
}

interface Props {
  /**
   * Copy tooltip text
   */
  copyTooltip?: string;
  /**
   * Handler triggered on copy to clipboard success
   * @param {string} value
   */
  onCopySuccess?: (value?: string) => void;
  /**
   * Handler triggered on copy to clipboard error
   * @param {CopyError} value copied value
   */
  onCopyError?: (error: CopyError) => void;
}

export type CopyFieldProps = Props & TextFieldProps;

const IconButtonWithTooltip = styled(IconButton)({
  root: {
      // backgroundColor:'black',
  },
});
const AlignedInputAdornment = styled(InputAdornment)({
  // margin: '0 auto', // fix for vertically unaligned icon
  position:'absolute',
});

export function CopyField({ onCopyError, onCopySuccess,label, copyTooltip = 'Copy', value, ...rest }: CopyFieldProps) {
  const [disabled, setDisabled] = useState(true);

  useEffect(() => {
    const enabled = 'clipboard' in navigator;
    setDisabled(!enabled);

    if (!enabled) {
      typeof onCopyError == 'function' && onCopyError(CopyError.NotAvailable);
    }
  }, []);

  const copyText = () => {
    if ('clipboard' in navigator) {
      navigator.clipboard.writeText(value as string).then(
        () => {
          typeof onCopySuccess == 'function' && onCopySuccess(value as string);
        },
        () => {
          typeof onCopyError == 'function' && onCopyError(CopyError.WriteError);
        }
      );
    } else {
      typeof onCopyError == 'function' && onCopyError(CopyError.NotAvailable);
    }
  };
  const [t] = useTranslation()
  return (
    <Container>
      <LabelTitle>{label}:</LabelTitle>
      <Input
        type="text"
        isBlocked
        customStyles={{
          color:'#000',
          width:'100%',
          boxSizing: 'border-box',
          paddingRight:'60px',
        }}
        value={value}
        {...rest}
      />
          <Tooltip
              enterTouchDelay={0}
              sx={{position:'absolute', bottom:'8px', right:'10px'}} title={copyTooltip}>
            <IconButtonWithTooltip role={'button'} disabled={disabled} onClick={copyText}>
              <img src={CopyIcon}/>
            </IconButtonWithTooltip>
          </Tooltip>
    </Container>
  );
}

function DefaultCopyField(props: TextFieldProps) {
  const [tooltip, setTooltip] = useState('Copy');
  const setCopied = () => setTooltip('Copied!');
  const setCopyError = () => setTooltip('Copy Error!');
  return <CopyField copyTooltip={tooltip} onCopySuccess={setCopied} onCopyError={setCopyError} {...props} />;
}

export default DefaultCopyField