import React, {FC, useContext, useEffect, useState} from 'react';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import SignInSchema from './signIn.schema'
import { useFormik } from 'formik';
import {Input, Button} from '../../components'
import {SubTitleWrapper, Nav, StyledBox} from './styles'
import {useNavigate} from 'react-router-dom'
import { authService } from '../../api';
// import {authService} from '../../Api'
import {setClientToken} from '../../api/apiClient'
// import { UIContext } from '../../Context/ui.context';
// import { useAuth } from '../../Context/auth.context';
import { ToastContainer, toast } from 'react-toastify';
import { useLocalStorage } from '../../hooks/useLocalStorage';
import { useAuth } from '../../context/auth.context';
import { TransitionProps } from '@mui/material/transitions';
import Slide from '@mui/material/Slide';
import Dialog from '@mui/material/Dialog';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';


const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>,
) {
  return <Slide direction="down" ref={ref} {...props} />;
});
type SignInProps = {
  isOpen:boolean,
  handleClose:() => void,
  goToAnother:() => void,
}



const SignIn:FC<SignInProps> = (props) => {
  const navigate = useNavigate()
  const [t] = useTranslation()
  // const {UpdateFunc} = useAuth()
  const [initialValues, setInitialValues] = useState<any>({
    email: '',
    password: '',
  });
  const [width, setWidth] = useState<any>();
  const [loading, setLoading] = useState<boolean>(false)
  const {
    setDataToLs,
    getDataFromLs,
  } = useLocalStorage()
  const {
    updateUser
  } = useAuth()
  // const { setAlert } = useContext(UIContext);
  const style = {
    // position: 'absolute' as 'absolute',
    // top: '50%',
    // left: '50%',
    // transform: 'translate(-50%, -50%)',
    // width: width < 700 ? '316px' : '416px',
    // height: '364px',

  };
  const Submit = async (data: any) => {
    setLoading(true)
    let postData = {
      email:data.email,
      password:data.password,
    }
   
    authService.signIn(postData)
    .then(async (res:any) => {
      console.log('res',res)
      await setDataToLs([
        'authToken',
        res.data.token,
      ])
      await setDataToLs([
        'chatId',
        res.data.userChat,
      ])
      await updateUser()
      await setLoading(false)
      await handleClose()
      await navigate('/profile')
    })
    .catch((e:any) => {
      console.log('error::', e)
      toast.error(e.response.data.message, {
        position: "bottom-right",
        autoClose: 5000,
        // hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
        });
      setLoading(false)
    })
  };
    const {
      values,
      errors,
      touched,
      isSubmitting,
      handleSubmit,
      handleChange,
      resetForm,
    } = useFormik({
      enableReinitialize: true,
      validationSchema: yup.object().shape({
        email: yup.string().email(`${t("NOT_VALID_EMAIL")}`).required(`${t("REQUIRED_FIELD")}`),
        password: yup.string().required(`${t("REQUIRED_FIELD")}`),
      }),
      initialValues,
      onSubmit: (value) => Submit(value),
    });

  const {
    isOpen,
    handleClose,
    goToAnother,
  } = props

  const formikProps = {
    errors,
    values,
    touched,
    handleChange,
  };
  useEffect(() => {
    setWidth(window.outerWidth)

    const listener = (event:any) => {
      if (event.code === "Enter" || event.code === "NumpadEnter") {
        event.preventDefault();
        handleSubmit()
      }
    };
    document.addEventListener("keydown", listener);
    return () => {
      document.removeEventListener("keydown", listener);
    };
  }, []);
  return(
    // <Modal
    //   open={isOpen}
    //   onClose={handleClose}
    // >
    <Dialog
      open={isOpen}
      TransitionComponent={Transition}
      keepMounted
      onClose={handleClose}
      // aria-describedby="alert-dialog-slide-description"
    >
      <StyledBox>
        <Input
          fieldName={'email'}
          title={t("EMAIL")}
          customStyles={{color:'#000'}}
          {...formikProps}
        />
        <Input
          fieldName={'password'}
          title={t("PASSWORD")}
          customStyles={{color:'#000'}}
          {...formikProps}
          isPassword={true}
        />
        <Button customStyles={{marginTop:'24px'}} loading={loading} title={t("SIGN_IN")} onClick={() => handleSubmit()}>{t("SIGN_IN")}</Button>
        <SubTitleWrapper>
          {/* <Nav onClick={() => {
            handleClose()
            navigate('/reset-password')
            }}>
            Forgot password?
          </Nav> */}
          <Nav onClick={() => goToAnother()}>
            {t("SIGN_UP")}
          </Nav>
        </SubTitleWrapper>
      </StyledBox>
    {/* </Modal> */}
    </Dialog>
  )
}
export default SignIn
