import React, { useEffect, useRef, useState } from 'react';
import Config from "../Config";
import socketIOClient from "socket.io-client";
import { useAuth } from './auth.context';
import { useLocalStorage } from '../hooks/useLocalStorage';
import boopSfx from './new_message.mp3';
import open from './open.mp3';
import axios from 'axios';
interface ChatContextInterface {
  messages:any[],
  getNewMessages:() => void,
  newMessage:(data:any) => void,
  setRoomId:(id:string) => void,
  chats:any[],
  chatUser:any,
  readMessages:(data:any) => void,
  roomId:string,
  lastMessage:any,
  isReadHeader:any,
  setIsReadHeader:(status:boolean) => void,
  chatModal:boolean,
  setChatModal:(status:boolean) => void,
  chatReady?:boolean,
  handleOpenChat?:() => void,
}

const ChatContext = React.createContext<Partial<ChatContextInterface>>({});

const ChatProvider = (props:any) => {
  const audioRef:any = useRef()
  const audioPrevRef:any = useRef()
  const socket = useRef<any>(null)
  const [messages, setMessages] = useState<any[]>([])
  const [chats, setChats] = useState<any[]>([])
  const [chatReady, setChatReady] = useState<boolean>(false)
  const {isAdmin, user, loadingUser, messageId} = useAuth()
  const [chatUser, setChatUser] = useState<any>({})
  const [lastMessage, setLastMessage] = useState<any>({})
  const [isReadHeader, setIsReadHeader] = useState<boolean>(false)
  const [chatModal, setChatModal] = useState<boolean>(false)
  const [roomId, setRoom] = useState<string>('')
  const [firstClick, setFirstClick] = useState<boolean>(false)
  const [ip, setIp] = useState<boolean>(false)
  const {
    setDataToLs,
    getDataFromLs,
    removeItemFromLs,
  } = useLocalStorage()

  const handleOpenChat = () => {
    if(!firstClick && audioRef.current){
      audioRef.current.volume = 0.1;
      audioRef?.current?.play()
    }
    setFirstClick(true)
    setChatModal(!chatModal)
  }
  // const openAuthSocket = () => {

  // }

  // const openAnonSocket = () => {

  // }
  // useEffect(() => {
  //   if(user) {
  //     openAuthSocket()
  //   } else {
  //     if(getDataFromLs({key:'socketAnonSession'}))
  //     openAnonSocket()
  //   }
  // },[])

  const setRoomId = async (id:string) => {
    if(id === roomId) {
      return
    }
    if(socket.current?.connected === true){
      socket?.current?.disconnect()
    }
      setMessages([])
      setRoom(id)
  }

  const UpdateChatList = (room:any) => {
    const found = chats.findIndex((item:any) => item?._id === room?._id)
    let newChats = chats
    newChats.splice(found, 1)
    newChats.unshift(room)
    setChats(newChats)
  }

  const initChat = async () => {
    if(loadingUser){
      return
    }
    const ipRes = await axios.get("https://ipapi.co/json/");
    const chatId = user?.userChat || getDataFromLs({key:'chatId'})?.chatId
    socket.current = socketIOClient(`${Config.socketUrl}`||'', {
    query: {
      roomId: chatId,
      skip:0,
      isAdmin:isAdmin,
      user: user?._id || null,
      ip:ipRes?.data?.ip,
      country:ipRes?.data?.country_name
    },
    path: `/chat/`,
    transports: ["websocket"],
    });
    socket.current.emit("join_room");
    socket.current.on("roomData", (data:any) => {
      setDataToLs(['chatId', {chatId:data._id}])
      setRoom(data._id)
      setDataToLs(['botMessageId', {botMessageId:data.botMessageId}])
      setMessages(data.messages)
      setLastMessage(data.lastMessage)
      if(data?.botMessageAdminGroupId === data?.botMessageId){
        setTimeout(() => {
          setChatReady(true)
        },5000)
      } else {
        setChatReady(true)
      }

    })
    socket.current.on("get_messages", async (data:any) => {
      // const [play] = useSound(boopSfx);
      console.log('data.messages',data.messages);
      setMessages(data.messages)
      
      if(data.messages.filter((mess:any) => !mess?.isRead && mess?.adminMessage)?.length > 0){
        // audio.play()
        audioRef.current.src = boopSfx
        audioRef.current.play()
      }
    })
    socket.current.on("new_message", (data:any) => {
      setMessages(data.messages)
      
    })
  }
  useEffect(() => {
    initChat()
  },[messageId, loadingUser])
  const updateChatList = () => {
    if(isAdmin){
      // setInterval(() => {
      //   socket.current.emit("update_chatlist")
      // },1000)
    }
  }
  useEffect(() => {
    updateChatList()
  },[isAdmin])
  const getNewMessages = () => {
    let dataToSend = {
      roomId: roomId,
      skip:messages.length,
    }
    socket.current.emit("get_messages", dataToSend)
  }
  const newMessage = (data:any) => {
    socket.current.emit("new_message", {
      ...data,
      // roomId:roomId,
      botMessageId: messageId || user?.userChat || getDataFromLs({key:'botMessageId'})?.botMessageId
    })
  }
  const readMessages = (data:any) => {
    console.log('dataq',data);
    socket.current.emit("read_messages", data)
  }
  
  return (
    <ChatContext.Provider
      value={{
        messages,
        getNewMessages,
        newMessage,
        setRoomId,
        chats,
        chatUser,
        readMessages,
        roomId,
        lastMessage,
        isReadHeader,
        setIsReadHeader,
        chatModal,
        setChatModal,
        chatReady,
        handleOpenChat
      }}
      {...props}
    >
      {props.children}
      <audio style={{display:'none'}} src={open} ref={audioRef}/>
      {/* <audio style={{display:'none'}} src={open} ref={audioPrevRef}/> */}
    </ChatContext.Provider>
  );
};

const useChat = () => {
  const context = React.useContext(ChatContext);
  if (context === undefined) {
    throw new Error('useChat must be used within a ChatProvider');
  }
  return context as ChatContextInterface;
};

export { ChatProvider, useChat };
