import * as yup from 'yup';
// import {ValidationKit} from '../../Kits';

// const {isRequired, maxLength, minCharacters} = ValidationKit;

export const ContactFormSchema = yup.object().shape({
  email: yup
  .string()
  .email()
  .required(),
  name: yup
  .string()
  .required(),
  phone: yup
  .string()
  .required(),
  message: yup
  .string()
  .required(),
});