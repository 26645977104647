import styled from "styled-components";
type ArrowProps ={ 
  hover?:boolean
  isBlack?:boolean
}
export const Container = styled.button<ArrowProps>`
  /* width:100%; */
  display:flex;
  justify-content: center;
  align-items: center;
  font-style: normal;
  font-family: Manrope;
  color:${({isBlack}) => isBlack ? '#FFF' : "#000"};
  background-color:transparent;
  font-weight: 500;
  font-size: 18px;
  /* background-color: #1F5EFA; */
  line-height: 27px;
  min-height:39px;
  box-sizing: border-box;
  padding:14px 30px;
  border: 2px solid #1F5EFA;
  border-radius: 16px;
  outline: none;
  &:hover{
    cursor: pointer;
    background-color: #FFFF;
    color:#1F5EFA;

  }
  &:active{
    /* background-color: #065299; */
  }
  &:disabled{
    /* background-color: grey; */
  }
  @media screen and (max-width: 700px){
    font-weight: 500;
    font-size: 18px;
    border: 1px solid #1F5EFA;
    height:56px;
    border-radius: 12px;
  }
`

export const ArrowStyle = styled.img<ArrowProps>`
  transform: ${({hover}) => hover ? 'translate(24px)' : 'translate(14px)'};
  @media screen and (min-width: 700px){
    max-width: 15px;
    max-height: 15px;
    margin-right: 15px;
  }
`