import {
  Container,
  CurrencyContainer,
  TitleWrapper,
  CurrenciesWrapper,
  ItemText,
  ItemTextBold,
  ItemImage,
} from './styles'
import React, {FC} from 'react'
import { Typography } from '@mui/material'
import handleImageUrl from '../../../../hooks/useImageUrl'
import { useTranslation } from 'react-i18next'

type ReservesProps = {
  currencies:any[]
}

const Reserves:FC<ReservesProps> = ({currencies}) => {
  const [t] = useTranslation()
  return (
    <Container>
      <Typography  sx={{fontSize:'64px', color:'#000'}} variant="h3">{t("RESERVES")}</Typography>
      <CurrenciesWrapper>
        {currencies.length > 0 && currencies.map((currency:any) => (
          <CurrencyContainer>
            <ItemImage src={handleImageUrl(currency.icon)}/>
            <TitleWrapper>
              <ItemTextBold>
                {currency.name}
              </ItemTextBold>
              <ItemText>
                {currency.reserves}
                {/* 18692473832 */}
              </ItemText>
            </TitleWrapper>
          </CurrencyContainer>
        ))}
      </CurrenciesWrapper>
    </Container>
  )
}

export default Reserves