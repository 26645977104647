import styled from "styled-components";


export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color:#101010;
  box-sizing: border-box;
  padding:80px 120px;
  width: 100%;
  border-radius:50px;

  @media screen and (max-width: 700px){
    border-radius:30px;
    padding:50px 20px;
  }
`
type TrustProp = {
  ind?:any;
}
export const TrustContainer = styled.div<TrustProp>`
  padding: 28px 0px 0px;
  border-top: 2px solid #6B6B6B;
  display: flex;
  flex-direction: column;
  gap:18px;
  margin-top:${({ind}) => `${ind}00px`};
  @media screen and (max-width: 700px){
    margin-top:20px;
  }
`

export const TrustWrapper = styled.div`
  display: flex;
  /* flex-direction: column; */
  gap:24px;
  @media screen and (max-width: 700px){
    flex-direction: column;
  }
`

export const TitleWrapper = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  gap:18px;

`

export const Icon = styled.img`

`

export const Description = styled.p`
  color: #9B9B9B;
  font-family: Manrope;
  font-style: normal;
  font-weight: 300;
  opacity: 0.75;
  font-size:20px;
  @media screen and (max-width: 700px){
    margin: 0px;
  }
`

export const Title = styled.p`
  color: #FFF;
  font-family: Manrope;
  font-style: normal;
  font-weight: 600;
  font-size:28px;
`
