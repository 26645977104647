import {
  Ticker,
  TickerWrapper,
  LogoImg,
  Container,
  TickerCurrency,
  TickerPrice,
  PricesWrapper,
  CurrencyContainer,
  TickerTwo,
  StyledLink,
  HeaderViewContainer,
  MobileMenuItemHide,
  StyledLinkP,
  TitleLogo,
  LangItem,
  LangItemText,
  BrowserMenuItemHide,
  LogoWrapper
} from './styles'
import React, { FC, useEffect, useState } from 'react'
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import {
  SignIn,
  SignUp,
  ClientChat,
  StrokeButton
} from '../../components'
import { Link, useNavigate } from 'react-router-dom';
import { useAuth } from '../../context/auth.context';
import Avatar from '@mui/material/Avatar';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { useLocalStorage } from '../../hooks/useLocalStorage';
import FlagEng from './flag-eng.svg'
import FlagRu from './flag-ru.svg'
import { useTranslation } from 'react-i18next';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import { useChat } from '../../context/chat.context';
import Logo from './logo.svg'
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import handleImageUrl from '../../hooks/useImageUrl';
import { addingApiService } from '../../api';
import Arrow from './arrow.svg'
import MobileLogo from './logo-mobile.svg'
import BurgerIcon from './burger-ic.svg'
import { useCrypto } from '../../context/crypto.context';
const Header:FC = () => {
  const navigate = useNavigate()
  // const [anchorElNav, setAnchorElNav] = useState<null | HTMLElement>(null);
  const [signInMOdal, setSignInModal] = useState<boolean>(false)
  const [signUpMOdal, setSignUpModal] = useState<boolean>(false)
  const [drawer, setDrawer] = useState<boolean>(false)
  const [alert, setAlert] = useState<any[]>([])
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  // const open = Boolean(anchorEl);
  const {
    removeItemFromLs
  } = useLocalStorage()
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const {setScrollTo} = useCrypto()
  const [t] = useTranslation()
  const {user, lang, setLang} = useAuth()
  const goToSignUp = () => {
      setSignInModal(false)
      setSignUpModal(true)
    }
    const goToSignIn = () => {
      setSignUpModal(false)
      setSignInModal(true)
    }
  const handleCloseNavMenu = () => {
    // setAnchorElNav(null);
  };
  const pages = [
    {title:t("RULES"), nav:'rules'},
    // {title:t("BLOG"), nav:'blog'},
    // {title:t("ORDERS"), nav:'orders'}
  ];
  const handleChange = (event: SelectChangeEvent) => {
    localStorage.setItem('lang_bestchange', event.target.value)
    // setLang((prev:any) => event.target.value as string);
    window.location.reload()
  };
  const {
    chatModal,
    setChatModal,
  } = useChat()
  const getAlert = () => {
    addingApiService.getAlerts()
    .then((res:any) => {
      setAlert(res.data)
    })
    .catch(() => {
      console.log('error get alerts');
    })
  }
  useEffect(() => {
    getAlert()
    const interval = setInterval(() => {
      getAlert()
    },60000)
    return () => {
      clearInterval(interval)
    }
  },[])
  const scroll = (to:string) => {
    setScrollTo(to)
  };
  return (
    <Container>
        <Drawer
          anchor={'left'}
          open={drawer}
          onClose={() => setDrawer(false)}
        >
          <Box style={{padding:'20px 30px', boxSizing:'border-box',backgroundColor: '#242424', height:'100%', width:'60vw'}}>
            <StyledLinkP
                onClick={() => {
                  navigate(`/`)
                  setDrawer(false)
                }}
                // sx={{ my: 2, color: 'white', display: 'block' }}
              >
                {t("MAIN")}
              </StyledLinkP>
            {pages.map((page) => (
              <StyledLinkP
                onClick={() => {
                  navigate(`/${page.nav}`)
                  setDrawer(false)
                }}
                key={page.title}
              >
                {page.title}
              </StyledLinkP>
            ))}
              <StyledLinkP
                onClick={() => {
                  navigate(`/`)
                  scroll('contact-us')
                  setDrawer(false)
                }}
              >
                {t("CONTACTS")}
              </StyledLinkP>
              {/* <StyledLinkP
                onClick={() => {
                  navigate(`/`)
                  scroll('faq')
                  setDrawer(false)
                }}
              >
                {t("FAQ")}
              </StyledLinkP> */}
            {!user &&
              <>
                <StyledLinkP
                  onClick={() => {
                    setSignUpModal(!signUpMOdal)
                    setDrawer(false)
                  }}
                >
                  {t("SIGN_UP")}
                </StyledLinkP>
                <StyledLinkP
                  onClick={() => {
                    setSignInModal(!signInMOdal)
                    setDrawer(false)
                  }}
                >
                  {t("SIGN_IN")}
                </StyledLinkP>
              </>
            }
              <Select
              IconComponent={() => (
                // <img src={Arrow}/>
                <></>
              )}
              sx={{
                color: 'white',
                boxShadow: "none",
                ".MuiOutlinedInput-notchedOutline": { border: 0 },
                "&.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline":
                  {
                    border: 0,
                  },
                "&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                  {
                    border: 0,
                  },
              }}
              value={lang}
              onChange={handleChange}
            >
              <MenuItem value={'en'}>
                <LangItem>
                  <LangItemText>
                    EN
                  </LangItemText>
                  <img style={{height:'30px'}} src={FlagEng}/>
                </LangItem>
              </MenuItem>
              <MenuItem value={'ru'}>
                <LangItem>
                  <LangItemText>
                    RU
                  </LangItemText>
                  <img style={{height:'30px'}} src={FlagRu}/>
                </LangItem>
              </MenuItem>
            </Select>
          </Box>
        </Drawer>

        
      <HeaderViewContainer>
        <MobileMenuItemHide>
          <IconButton
            size="large"
            edge="start"
            onClick={() => setDrawer(true)}
            color="inherit"
            aria-label="menu"
           
          >
            <img  src={BurgerIcon} style={{ width:'24px', height:'24px' }} />
          </IconButton>
        </MobileMenuItemHide>
          <LogoWrapper onClick={() => navigate('/')} style={{display:'flex', alignItems:'center'}}>
            <LogoImg  src={MobileLogo} />
            {/* <TitleLogo onClick={() => navigate('/')}>
              Best Change
            </TitleLogo> */}
          </LogoWrapper>
          <BrowserMenuItemHide>
            {pages.map((page) => (
              <StyledLink
                key={page.title}
                to={`/${page.nav}`}
                // sx={{ my: 2, color: 'white', display: 'block', padding:'0px' }}
              >
                {page.title}
              </StyledLink>
            ))}
              <StyledLinkP
                onClick={() => {
                  navigate(`/`)
                  scroll('contact-us')
                  setDrawer(false)
                }}
              >
                {t("CONTACTS")}
              </StyledLinkP>
              {/* <StyledLinkP
                onClick={() => {
                  navigate(`/`)
                  scroll('faq')
                  setDrawer(false)
                }}
              >
                {t("FAQ")}
              </StyledLinkP> */}
            {!user ? 
              <StyledLinkP
                onClick={() => setSignUpModal(!signUpMOdal)}
                // sx={{ my: 2, color: 'white', display: 'block', padding:'0px' }}
              >
                {t("SIGN_UP")}
              </StyledLinkP>
            : null}
          </BrowserMenuItemHide>
         
          <div style={{display:'flex', alignItems:'center', gap:'30px'}}>
            <BrowserMenuItemHide>
              <Select
                IconComponent={() => (
                  // <img src={Arrow}/>
                  <></>
                )}
                sx={{
                  color: 'white',
                  boxShadow: "none",
                  padding:'16px 0px',
                  
                  ".MuiOutlinedInput-notchedOutline": { border: 0 },
                  "&.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline":
                    {
                      border: 0,
                    },
                  "&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                    {
                      border: 0,
                    },
                }}
                value={lang}
                onChange={handleChange}
              >
                <MenuItem value={'en'}>
                  <LangItem>
                    <LangItemText>
                      EN
                    </LangItemText>
                    <img style={{height:'30px'}} src={FlagEng}/>
                  </LangItem>
                </MenuItem>
                <MenuItem value={'ru'}>
                  <LangItem>
                    <LangItemText>
                      RU
                    </LangItemText>
                    <img style={{height:'30px'}} src={FlagRu}/>
                  </LangItem>
                </MenuItem>
              </Select>
            </BrowserMenuItemHide>
            {user ? 
              <div style={{display:'flex'}} >
                {/* @ts-ignore */}
                <Avatar sx={{cursor:'pointer'}} onClick={handleClick} src={handleImageUrl(user?.image)}>
                  {user.email[0]}
                </Avatar>
                <Menu
                  PaperProps={{
                    elevation: 0,
                    sx: {
                      overflow: 'visible',
                      filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                      mt: 1.5,
                      '& .MuiAvatar-root': {
                        width: 32,
                        height: 32,
                        ml: -0.5,
                        mr: 1,
                      },
                      '&:before': {
                        content: '""',
                        display: 'block',
                        position: 'absolute',
                        top: 0,
                        right: 14,
                        width: 10,
                        height: 10,
                        bgcolor: 'background.paper',
                        transform: 'translateY(-50%) rotate(45deg)',
                        zIndex: 0,
                      },
                    },
                  }}
                  transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                  anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
                  anchorEl={anchorEl}
                  open={Boolean(anchorEl)}
                  onClose={handleClose} 
                >
                  <MenuItem onClick={() => navigate('/profile')}>
                    {t("PROFILE")}
                  </MenuItem>
                  <MenuItem onClick={() => {
                    removeItemFromLs('authToken')
                    window.location.reload()
                  }} >
                    {t("LOGOUT")}
                  </MenuItem>
                </Menu>
              </div>
            :
              <StrokeButton
                customStyles={{borderColor:'#FFF', borderWidth:'1px'}}
                onClick={() => setSignInModal(!signInMOdal)}
                isBlack
                title={t("SIGN_IN")}
                // color='secondary'
                // sx={{my: 2, backgroundColor: 'secondary', color:'#FFF', display: 'block' }}
              />
            }
        </div>
      </HeaderViewContainer>
      {alert?.length > 0 ? 
        <TickerWrapper>
          <Ticker>
            <PricesWrapper>
              {alert.map((item:any) => (
                <CurrencyContainer>
                  <TickerCurrency>
                    {item.symbol}
                  </TickerCurrency>
                  <TickerPrice>
                    $ {item.current_price?.toFixed(4)}
                  </TickerPrice>
                </CurrencyContainer>
              ))}
            </PricesWrapper>
          </Ticker>
          <TickerTwo>
            <PricesWrapper>
              {alert.map((item:any) => (
                <CurrencyContainer>
                  <TickerCurrency>
                    {item.symbol}
                  </TickerCurrency>
                  <TickerPrice>
                    $ {item.current_price?.toFixed(4)}
                  </TickerPrice>
                </CurrencyContainer>
              ))}
            </PricesWrapper>
          </TickerTwo>
        </TickerWrapper>
      : null}
      <SignIn
        goToAnother={goToSignUp}
        isOpen={signInMOdal}
        handleClose={() => setSignInModal(false)}
      />
      <SignUp
        goToAnother={goToSignIn}
        isOpen={signUpMOdal}
        handleClose={() => setSignUpModal(false)}
      />
      <ClientChat
        isOpen={chatModal}
        handleClose={() => setChatModal(false)}
      />
    </Container>
  );
}

export default Header