import { Typography } from '@mui/material'
import React, { FC, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { ruleService } from '../../api'
import { useAuth } from '../../context/auth.context'
import {
  Container,
  Header,
  BodyRule,
  RuleTitle,
  RuleText,
  RuleContainer,
} from './styles'

type Rule = {
  title:string,
  message:string,
  items:any[],
}

const RulePage:FC = () => {
  const [t] = useTranslation()
  const [rules, setRules] = useState<Rule[]>([])
  const {lang} = useAuth()
  const getRules = () => {
    ruleService.getRules()
    .then((res) => {
      setRules(res.data)
    })
  }
  useEffect(() => {
    getRules()
  },[])

  return(
    <Container>
      <Header>
        <Typography variant='h2'>{t("SERVICE_RULES")}</Typography>
      </Header>
      <RuleContainer>
        {rules.map((rule:any, index) => (
          <BodyRule>
            <RuleTitle>
              {index+1}.{rule[lang].title}
            </RuleTitle>
            <RuleText>
              {rule[lang].message}
            </RuleText>
            {rule[lang].items.length > 0 && rule[lang].items.map((item:any, ind:any) => (
              <RuleText>
                {index+1}.{ind+1}. {item.title}
              </RuleText>
            ))}
          </BodyRule>
        ))}
      </RuleContainer>
    </Container>
  )
}

export default RulePage
