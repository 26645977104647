import React, {FC, useEffect, useRef, useState} from 'react'
import {
  Container,
  ChatHeader,
  ChatMessage,
  BodyChat,
  MessageWrapper,
  CloseImage,
  IconsWrapper,
  InputWrapper,
  OldaMessagesGetBox,
  Emoji,
  DotImage,
  InputContainer,
  MessageImage,
  FileContainer
} from './styles'
import Plus from './plus.svg'
import SendMsg from './send-ic.svg'
import Smiles from './smile-ic.svg'
import { useChat } from "../../context/chat.context";
import { useAuth } from "../../context/auth.context";
import PurpleDot from './purpleDot.svg'
import EmojiPicker from 'emoji-picker-react';
import { useLocalStorage } from '../../hooks/useLocalStorage'
import { Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { PhotoProvider, PhotoView } from 'react-photo-view';
import 'react-photo-view/dist/react-photo-view.css';
import File from './paper-clip.svg'
import FileSend from './paperclip.svg'

import { ToastContainer, toast } from 'react-toastify';
import { addingApiService } from '../../api'
import handleImageUrl from '../../hooks/useImageUrl'


type ChatProps = {
  isOpen?:boolean,
  handleClose?:() => void,
  goToAnother?:() => void,
  isAdmin?:boolean,
}
 

const ClientChat:FC<ChatProps> = (props) => {
   const {
    messages,
    getNewMessages,
    newMessage,
    setRoomId,
    chatUser,
    roomId,
    readMessages,
    lastMessage
  } = useChat()
  const [isMe, setIsMe] = useState<boolean>(false)
  const {
    isOpen,
    handleClose,
    isAdmin,
  } = props
  const [t] = useTranslation()
  const inputRef:any = useRef()
  const {user} = useAuth()
  const scrollbars:any = useRef(null)
  const [error, setError] = useState<string>('')
  const [file, setFile] = useState<any>()
  const [prev, setPrev] = useState<any>()
  useEffect(() => {
    if(lastMessage?.user?._id !== user?._id){
      // setIsReadHeader(false)
    }
  },[messages])
  const [chosenEmoji, setChosenEmoji] = useState<boolean>(false);
  const emoji:any = useRef(null)
  const handleClickOutside = (event:any) => {
    if (chosenEmoji) {
      if (emoji && !emoji.current.contains(event.target)) {
        setChosenEmoji(false)
      }
    }
  }
  
  useEffect(() => {
    document.addEventListener('click', handleClickOutside, true);
    return () => {
      document.removeEventListener('click', handleClickOutside, true);
    };
  },[]);
  const handleUpdate = () => {
    if (scrollbars.current) {
      const offsetBottom = scrollbars.current.offsetTop + scrollbars.current.offsetHeight;
      scrollbars.current.scroll({ 
        top: scrollbars.current.scrollHeight, 
        // behavior: 'smooth' 
    });
    }
  }
  const {getDataFromLs} = useLocalStorage()
  const [initialValues, setInitialValues] = useState<any>({
    message: '',
  });
  const Submit = async () => {
    if(inputRef.current.value === '') {
      return 
    }
      try {
        let dataToSend = await {
          user:user?._id,
          message:inputRef.current.value,
          timeStamp:Date.now(),
          roomId:getDataFromLs({key:'chatId'})?.chatId,
          adminMessage:isAdmin
        }
        await newMessage(dataToSend)
        // await resetForm()
        inputRef.current.value = ''
        setTimeout(() => {
          handleUpdate()

        },300)
      } catch (error: any) {
        console.log('err chat message', error);
      }
    };

  const addEmoji = (event:any, emojiObject:any) => {
    const emoji = event.emoji;
    inputRef.current.value = inputRef.current.value + emoji
  };
  const handleDrop = (e:any) => {
    if(e.target.files[0].type !== 'image/png' && e.target.files[0].type !== 'image/jpeg'){
      toast.error('Type image must be png or jpeg', {
        position: "bottom-right",
        autoClose: 5000,
        // hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
      return
    }
    if(e.target.files[0].size >  5000000 ){
      toast.error('Image size must be less than 5mb', {
        position: "bottom-right",
        autoClose: 5000,
        // hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
      return
    }
    if(!e.target.files[0]){
      return
    }
    e.preventDefault();
    const reader = new FileReader();
    const file = e.target.files[0]
    reader.onloadend = () => {
      setFile(file)
      addingApiService.uploadImage({file})
      .then((res) => {
        let dataToSend = {
          user:user?._id,
          message: handleImageUrl(res.data.image),
          timeStamp:Date.now(),
          roomId:getDataFromLs({key:'chatId'})?.chatId,
          adminMessage:isAdmin
        }
        newMessage(dataToSend)
        // await resetForm()
        setTimeout(() => {
          handleUpdate()
        },300)
      })
    }
    reader.readAsDataURL(file)
  }
  
  useEffect(() => {
    handleUpdate()
  },[messages])
  useEffect(() => {
    const listener = (event:any) => {
      if (event.code === "Enter" || event.code === "NumpadEnter") {
        event.preventDefault();
        Submit()
        handleUpdate()
      }
    };
    document.addEventListener("keydown", listener);
    return () => {
      document.removeEventListener("keydown", listener);
    };
  }, []);
  // if(isAdmin && chatUser?._id === user?._id) return <Container isAdmin={isAdmin}></Container>
  // if(!isOpen) return<></>
  return (
      <Container isOpen={isOpen} isAdmin={isAdmin}>
        <ChatHeader isAdmin={isAdmin}>
          {isAdmin ?
            <>
              <div></div>
                {chatUser?.name}
              <div></div>
            </>
          :
            <>
              {/* <CloseImage  src={Dots}/> */}
              <Typography sx={{color:'#FFF', fontSize:'18px'}} variant='h5'>
                {t("SUPPORT_CHAT")}
                </Typography>
              <CloseImage style={{transform: 'rotate(-45deg)'}} src={Plus}  onClick={handleClose}/>
            </>
          }
        </ChatHeader>
        <BodyChat ref={scrollbars}>
          <>
            {messages?.map((item) => (
              <MessageWrapper key={item?._id} isMe={!item.adminMessage}>
                {!item.adminMessage ? 
                  // <FileContainer>
                  //   {/* {item?.isRead ? null :
                  //     <img src={PurpleDot}/>
                  //   } */}
                  //   <ChatMessage
                  //       tailPosition={item.adminMessage ? 'right' : 'left'}>
                  //     {item.message}
                  //   </ChatMessage>
                  // </FileContainer>
                  <FileContainer tailPosition={item.adminMessage ? 'right' : 'left'}>
                  {item?.message?.indexOf('https://exchage-assets.s3.eu-north-1.amazonaws.com') !== -1 ? (
                    item?.message?.indexOf('.webp') !== -1 || 
                    item?.message?.indexOf('.jpg') !== -1  ||
                    item?.message?.indexOf('.png') !== -1 ||
                    item?.message?.indexOf('.jpeg') !== -1
                  ) ? 
                    <PhotoProvider  maskOpacity={0.8}>
                      <PhotoView src={item?.message}>
                      <MessageImage src={item?.message}/>
                      </PhotoView>
                    </PhotoProvider>
                      
                    : 
                    
                    <div style={{display:'flex', gap:'10px', alignItems:"center",color:"blue"}} onClick={() => window.open(item?.message)}>
                      <img style={{width:'20px'}} src={File}/>
                      File
                    </div>
                  :
                    <ChatMessage tailPosition={item.adminMessage ? 'right' : 'left'}>
                      {item.message}
                    </ChatMessage>
                  }

                </FileContainer>
                :
                  <>
                    {
                      <FileContainer>
                          {item?.file ? (
                            item?.file?.indexOf('.webp') !== -1 || 
                            item?.file?.indexOf('.jpg') !== -1  ||
                            item?.file?.indexOf('.png') !== -1 ||
                            item?.file?.indexOf('.jpeg') !== -1
                          ) ? 
                            <PhotoProvider  maskOpacity={0.8}>
                              <PhotoView src={item?.file}>
                              <MessageImage src={item?.file}/>
                              </PhotoView>
                            </PhotoProvider>
                              
                            : 
                            
                            <div style={{display:'flex', gap:'10px', alignItems:"center",color:"blue"}} onClick={() => window.open(item?.file)}>
                              <img style={{width:'20px'}} src={File}/>
                              File
                            </div>
                            : null}

                          <ChatMessage>
                            {item.message}
                          </ChatMessage>
                      </FileContainer>
                    }
                    {item?.isRead ? null :
                      <img style={{marginLeft:'6px'}} src={PurpleDot}/>
                    }
                  </>
                }
              </MessageWrapper>
            ))}
          </>
        </BodyChat>
        <InputWrapper>
          {/* <ChatInput/> */}
          <InputContainer
            onFocus={() => {
              readMessages({
                roomId:user?.userChat || getDataFromLs({key:'chatId'})?.chatId,
              })
            }}
            ref={inputRef}
          />
          <IconsWrapper>
            <label  style={{width: '550px'}} htmlFor='file-input-chat'>
              <CloseImage src={FileSend}/>
              <input style={{display:'none'}} accept="image/png, image/jpeg" type='file' id="file-input-chat" onChange={(e) => handleDrop(e)} />
            </label>
            <CloseImage isEmoji onClick={() => setChosenEmoji(!chosenEmoji)} src={Smiles}/>
            <Emoji
              chosenEmoji={chosenEmoji}
              ref={emoji}
            >
              <EmojiPicker lazyLoadEmojis={true} onEmojiClick={addEmoji} />
            </Emoji>
            <CloseImage onClick={() => Submit()} src={SendMsg}/>
          </IconsWrapper>
        </InputWrapper>
      </Container>
  )
}

export default ClientChat


