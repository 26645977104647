import ApiClient from "./apiClient";


const addingApiService = {
  getCurrencies:(queries:Object) => ApiClient.get('adding/courses', {
    params: {
      ...queries
    }
  }),
  convertCurrency:(queries:Object) => ApiClient.get('adding/huobi-convert',{
    params: {
      ...queries
    }
  }),
  getAlerts:() => ApiClient.get('adding/alert-courses'),
  uploadImage:(data:any) => ApiClient.post('adding/uploadImage', data, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  }),
}

export default addingApiService;