import styled from 'styled-components'
type ContainerProps = {
  isLoader?:boolean,
}
export const Container = styled.div<ContainerProps>`
  width:100%;
  /* min-height:100vh; */
  display:flex;
  /* flex-direction: column; */
  gap:15px;
`
export const ButtonWrapper = styled.div`
  width: 200px;
  @media screen and (max-width: 700px){
    max-width: 100px;
  }
`

export const ReviewsBox = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  background-color:#101010;
  padding:80px 100px;
  width: 100%;
  border-radius:50px;
  @media screen and (max-width: 700px){
    padding:16px;
    border-radius:30px;
    padding-top:50px;
    padding-bottom:50px;
  }
`

export const CreateReviewButtonWrapper = styled.div`
  display: flex;
  width:100%;
  box-sizing: border-box;
  justify-content: space-between;
  margin-bottom:85px;

  @media screen and (max-width: 700px){
    flex-direction: column;
    gap:10px;
    padding-right: 10px;
    padding-left: 10px;
  }
`

export const ErrorText = styled.div`
  width:100%;
  display: flex;
  justify-content: flex-end;
  color:#F42F2F;
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 17px;
  text-align: end;

`
type DotProps = {
  active?:boolean
}

export const Slideshow = styled.div`
  margin: 0 auto;
  overflow: hidden;
`

export const SlideshowSlider = styled.div`
  display: flex;
  gap:20px;
  white-space: nowrap;
  transition: ease 1000ms;
  height: 100%;
`

export const SlideshowDots = styled.div`
  text-align: center;
  margin-top:40px;
`

export const SlideshowDot = styled.div<DotProps>`
  display: flex;
  height: 15px;
  width: 15px;
  border-radius: 50%;
  cursor: pointer;
  margin: 25px 0px 0px;
  background-color:${({active}) => active ? '#1F5EFA' : '#c4c4c4'};

  @media screen and (max-width: 1280px){
    width: 8px;
    height: 8px;
  }

`


export const MobileButton = styled.div`
  display: none;
  @media screen and (max-width: 1280px){
    display: flex;
    margin-top:24px;
  }
`

export const WebButton = styled.div`
  display: flex;
  width:200px;
  @media screen and (max-width: 1280px){
    display: none;
  }
`