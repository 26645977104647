import { Link } from "react-router-dom";
import styled from "styled-components";


export const Container = styled.div`
  margin:0px;
  padding:0px;
`

export const HeaderViewContainer = styled.div`
  width: 100%;
  display: flex;
  height: 90px;
  flex-direction: row;
  padding:24px 82px;
  box-sizing: border-box;
  justify-content: space-between;
  align-items: center;
  color:#fff;
  background-color:#101010;
  margin:0px;
  /* padding:0px !important; */
  /* padding: 0px; */
  @media screen and (max-width: 700px){
    padding:14px 32px;
  }
`

export const NavWrapper = styled.div`
  display: flex;
  gap:15px;
  align-items: center;
  flex:1 auto;
`

export const AuthRightContainer = styled.div`
  display: flex;
  align-items: center;
  gap:10px;
`

export const NavItem = styled.div`
`
export const Ticker = styled.div`
  /* margin-left:10px ; */

  overflow: hidden;
  position: absolute;
  left: 100%;
  white-space: nowrap;
  animation: marquee 40s linear infinite;
  animation-duration: 48s;
  animation-delay: -48s;
  @keyframes marquee {
    0%{
      transform: translateX(100%);
    }
    
    100%{
      transform: translateX(-100%);
    }
  }
`
export const TickerTwo = styled.div`
  overflow: hidden;
  left: 100%;
  box-sizing: border-box;
  position: absolute;
  white-space: nowrap;
  animation: marquee 40s linear infinite;
  animation-duration: 48s;
  animation-delay: -24s;
  @keyframes marquee {
    0%{
      transform: translateX(0%);
    }
    
    100%{
      transform: translateX(-200%);
    }
      
  }
`

export const TickerWrapper = styled.div`
  background-color: #101010;
  box-sizing: border-box;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  /* border: 2px solid #000; */
  height: 60px;
  /* border-radius: 22px; */
  /* box-shadow: 4px 4px 0 #000; */
  overflow: hidden;
  /* background: white; */
  white-space: nowrap;
  border: 0.5px solid #6B6B6B;
  border-left:none;
`

export const TickerCurrency = styled.div`
  font-weight: bold;
  font-size: 16px;
  font-family: Manrope;
  
  color: #fff;
  color: #6B6B6B;
  text-transform: uppercase;
`
export const TickerPrice = styled.div`
  font-weight: normal;
  font-family: Manrope;
  
  color: #FFFEFE;
  font-size: 16px;
  text-transform: uppercase;
`
export const PricesWrapper = styled.span`
  display: flex;
  align-items:center;
  gap:20px;
  margin-left:20px;
  float: left;
  overflow: hidden;
`
export const CurrencyContainer = styled.div`
  display: flex;
  align-items:center;
  padding-right:20px;
  /* padding-left:20px; */
  border-right: 1px solid #6B6B6B;
  gap:10px;
`
export const LogoImg = styled.img`
  /* width:60px; */
  height: 60px;
  margin-right:20px;
  @media screen and (max-width: 700px){
    /* width:40px; */
    /* height: 40px; */
    margin-right:5px;
    height: 36px;

  }
`

export const DrawerButton = styled.div`
  display: none;
  @media screen and (max-width: 700px){
    display: flex;
  }
`
export const StyledLinkP = styled.p`
  text-decoration: none;
  height:20px;
  color:white;
  font-family: Manrope;
  text-transform:uppercase;
  &:hover {
    cursor:pointer;
  }
`
export const TitleLogo = styled.h6`
  text-decoration: none;
  color:white;
  font-family: Manrope;
  font-style: normal;
  font-weight: 600;
  font-size: 32px;
  &:hover {
    cursor:pointer;
  }
  @media screen and (max-width: 700px){
    font-size: 22px;
  }
`
export const StyledLink = styled(Link)`
  text-decoration: none;
  text-transform:uppercase;
  height:20px;
  color:white;
`
export const MobileMenuItemHide = styled.div`
  display: none;
  @media screen and (max-width: 700px){
    display: flex;
    /* padding: 20px; */
  }
`
export const BrowserMenuItemHide = styled.div`
  display: flex;
  align-items:center;
  gap:20px;
  @media screen and (max-width: 700px){
    display: none;
    /* padding: 20px; */
  }
`

export const LangItem = styled.div`
  display: flex;
  gap:10px;
  width: 100%;
  padding: 12px 6px;
  align-items: center;
  /* border-bottom:1px solid #DBDADA; */
  &:hover {
    cursor:pointer;
    border-bottom:1px solid #1F5EFA;
  }
`

export const LangItemText = styled.div`
  font-family: Manrope;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  text-transform:uppercase;
`

export const LogoWrapper = styled.div`
  &:hover {
    cursor:pointer;
  }
`