import { FC, useRef, useEffect, useState } from "react";
// import { chatService } from "../../Api";
import {
  Container,
  ToAutoSizer,
  ChatCard,
  MessageWrapper,
  ChatDescriptionWrapper,
  MobileChatWrapper,
  DesktopChatWrapper,
  MobileHeader,
  DotImage,
  TimeWrapper
} from './styles'
import {
  Avatar,
  Typography,
} from '@mui/material';
import {useChat} from '../../context/chat.context'
// import { useImageUrl } from '../../hooks';
import { toHHMM } from "../../utils/toHHMM";
// import VirtualList from 'rc-virtual-list';
// import {
//   AutoSizer as _AutoSizer,
//   List as _List,
//   ListProps,
//   AutoSizerProps,
// } from "react-virtualized";

// const AutoSizer = _AutoSizer as unknown as FC<AutoSizerProps>;
import { ClientChat } from "../../components";
import Drawer from '@mui/material/Drawer';
import Back from './arrowLeft.svg'
import PurpleDot from './purpleDot.svg'
import WhiteDot from './whiteDot.svg'
import { useAuth } from "../../context/auth.context";

const ChatList:FC = () => {
  const [width, setWidth] = useState<any>();
  useEffect(() => {
    if(typeof window !== 'undefined'){
      setWidth(window.outerWidth)
    }
  },[])
  const [isOpenDrawer, setIsOpenDrawer] = useState<boolean>(width < 700 ? true : false);
  const {
    user
  } = useAuth()
  const {
    chats,
    setRoomId,
    chatUser
  } = useChat()
  
  return (
    <Container>
      <MobileChatWrapper>
        <MobileHeader onClick={() => setIsOpenDrawer(true)}>
          <img style={{marginRight:'10px'}} src={Back}/>
          <div >Go To chat list</div>
        </MobileHeader>
        <Drawer
          anchor={'left'}
          open={isOpenDrawer}
          onClose={() => setIsOpenDrawer(false)}
        >
          <ToAutoSizer>
            {/* <AutoSizer>
              {({ height, width }) => {
                return (
                  <VirtualList
                    style={{width}}
                    data={chats}
                    height={height}
                    itemKey="comment"
                  >
                    {item => (
                      <ChatCard onClick={() => {
                        setIsOpenDrawer(false)
                        setRoomId(item._id)
                        }} key={item._id}>
                        <Avatar
                          alt={item.user?.name}
                          src={useImageUrl(item.user?.image)}
                        />
                        <ChatDescriptionWrapper>
                          <MessageWrapper>
                            <Typography variant="subtitle2">{item.user?.name}</Typography>
                            <Typography variant="subtitle2">{item.lastMessage?.message?.message}</Typography>
                          </MessageWrapper>
                          <TimeWrapper>
                            <Typography variant="subtitle2">
                              {toHHMM(item.lastMessage?.message?.timeStamp)} 
                            </Typography>
                            {item.lastMessage?.message?.isRead || item.lastMessage?.message?.user === user._id ? null :
                              <DotImage src={WhiteDot}/>
                            }
                          </TimeWrapper>
                        </ChatDescriptionWrapper>
                      </ChatCard>
                    )}
                  </VirtualList>
                )
              }}
            </AutoSizer> */}
          </ToAutoSizer>
        </Drawer>
      </MobileChatWrapper>
      <DesktopChatWrapper>
        <ToAutoSizer>
          {/* <AutoSizer>
            {({ height, width }) => {
              return (
                <VirtualList
                  style={{width}}
                  data={chats}
                  height={height}
                  itemKey="comment"
                >
                  {item => (
                    <ChatCard isActive={item?.user?._id === chatUser?._id} onClick={() => setRoomId(item._id)} key={item._id}>
                      <Avatar
                        alt={item.user?.name}
                        src={useImageUrl(item.user?.image)}
                      />
                      <ChatDescriptionWrapper>
                        <MessageWrapper>
                          <Typography>{item.user?.name}</Typography>
                          <Typography>{item.lastMessage?.message?.message}</Typography>
                        </MessageWrapper>
                        <TimeWrapper>
                          <Typography >
                            {toHHMM(item.lastMessage?.message?.timeStamp)} 
                          </Typography>
                          {item.lastMessage?.message?.isRead || item.lastMessage?.message?.user === user._id ? null :
                            <DotImage src={PurpleDot}/>
                          }
                        </TimeWrapper>
                      </ChatDescriptionWrapper>
                    </ChatCard>
                  )}
                </VirtualList>
              )
            }}
          </AutoSizer> */}
        </ToAutoSizer>
      </DesktopChatWrapper>
      <ClientChat isAdmin={true} isOpen={true}/>
      {/* <Chat/> */}
    </Container>
  );
}

export default ChatList;