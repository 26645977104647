import styled from "styled-components";
import SVG from "react-inlinesvg";

export const Container = styled.div`
  min-width:100%;
  min-height:100%;
  display:flex;
  flex-direction: column;
  justify-content: center;
`

export const RecommendedWrapper = styled.div`
  display: flex;
  width: 90vw;
  overflow-x: scroll;
  scroll-behavior: smooth;
  scrollbar-width: none;
  ::-webkit-scrollbar {
    display: none;
  }
  @media screen and (max-width: 700px){
    width: calc(100vw - 32px);
  }
`

export const ArrowButton = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  border: 1px solid #1078D9;
  border-radius: 100%;
  &:hover{
    cursor:pointer;
    border-color: #065299;
  }
  &:active{
    border-color: #065299;
  }
`
export const Title = styled.div`
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 600;
  font-size: 36px;
  line-height: 54px;
`

export const ButtonWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  width:104px;
  margin-bottom: 20px;
`

export const StyledIcon = styled(SVG)`

  &:hover{
    cursor:pointer;
    & path {
      stroke: #065299;
    };
  }
  &:active{
    cursor:pointer;
    & path {
      stroke: #065299
    };
  }
`