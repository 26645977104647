import React from 'react';
import logo from './logo.svg';
// import './App.css';
import './i18n';
import styled, {createGlobalStyle} from 'styled-components'
import StyledToastContainer from './components/StyledToastContainer';
import theme from './theme'
import { ThemeProvider, createTheme } from '@mui/material/styles';
import RootRouter from './router';
import { Footer, Header, Loader } from './components';
import { AuthProvider, useAuth } from './context/auth.context';
import { CryptoProvider } from './context/crypto.context';
import { ChatProvider } from './context/chat.context';
import { QueryParamProvider } from 'use-query-params';
import { ReactRouter6Adapter } from 'use-query-params/adapters/react-router-6';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import ScrollToTop from './scrollTo'
const GlobalStyle = createGlobalStyle<any>`
  body{
    /* background-color: rgb(30, 40, 44)!important; */
    background-color: #101010!important;
    font-family: Manrope;

  }
`
const PageWrapper = styled.div`
  display: flex;
  flex-direction:column;
  height: 100%;
  width: 100%;
  overflow-x: hidden;
`


  
function App() {
  const {loadingUser} = useAuth()
  console.log('toRelog')
  if(loadingUser) return <PageWrapper><Loader/></PageWrapper>
  return (
    <QueryParamProvider adapter={ReactRouter6Adapter}>
      <ThemeProvider theme={theme}>
        <StyledToastContainer hideProgressBar />
        <AuthProvider>
          <ChatProvider>
            <CryptoProvider>
              <PageWrapper>
                <Header/>
                <ScrollToTop/>
                <RootRouter/>
                {/* <Footer/> */}
              </PageWrapper>
            </CryptoProvider>
          </ChatProvider>
        </AuthProvider>
        <GlobalStyle/>
      </ThemeProvider>
    </QueryParamProvider>
  );
}

export default App;
