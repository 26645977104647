import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  gap:15px;
  width: 100%;
  box-sizing: border-box;
  padding:80px 120px;
  border-radius:50px;

  @media screen and (max-width: 700px){
    padding:10px 20px;
  }
`


export const PartnerContainer = styled.div`
  display: flex;
  flex-direction: row;
  /* justify-content: center; */
  align-items: center;
  gap: 20px;
  background-color: #FFFF;
  /* width: 200px; */
  padding: 8px 12px;
  border-radius: 8px;
  &:hover{
    cursor: pointer;
  }
`
export const PartnersWrapper = styled.div`
  /* display: flex;
  flex-direction: row;
  gap:40px;
  flex-wrap: wrap; */
  /* width: 560px; */
  display: flex;
  flex-direction: row;
  gap:40px;
  flex-wrap: wrap;
  justify-content: center;
  padding: 50px;
  width: 100%;
  @media screen and (max-width: 700px){
    width: 100%;
    gap:20px;
    padding: 10px;
  }
`

export const PartnerImage = styled.img`
  /* width:220px; */
  height:100px;
  background: #F7F7F7;
  padding: 40px 20px;
  border-radius: 20px;
  /* flex-grow:1; */
  object-fit: contain;
  /* box-sizing: border-box; */
  /* height: 64x; */

`

export const ToPartnersWrapper = styled.div`
  display: flex;
  justify-content:space-between;
  gap:120px;
  margin-top:40px;
  @media screen and (max-width: 700px){
    flex-direction:column;
    gap:20px;
  }
`
export const ToPartnersTitleWrapper = styled.div`
  display: flex;
  flex-direction:column;
  min-width: 293px;
  /* gap:6px; */
  
  align-items:flex-start;
`

export const HelloText = styled.p`
  font-family: Manrope;
  font-style: normal;
  font-weight: 500;
  font-size: 28px;
  color: #1F5EFA;
  width: 100%;
  margin: 0px;
`

export const ToPartnersText = styled.p`
  font-family: Manrope;
  font-style: normal;
  font-weight: 300;
  margin:0px 0px 10px 0px;
  font-size: 18px;
  color: #000;
  
`
type TextProp = {
  noWidth?:boolean;
}
export const HelloTextClients = styled.p<TextProp>`
  font-family: Manrope;
  font-style: normal;
  font-weight: 500;
  font-size: 28px;
  color: #000;
  width: ${({noWidth}) => noWidth ? '' : '100%'};
  margin: 0px;
  border-bottom: 1.5px solid #101010;
  padding-bottom:${({noWidth}) => noWidth ? '20px' : '40px'};
`

export const PartnerCardContainer = styled.div`
  display: flex;
  /* flex: auto 1; */
  flex-direction: column;
  justify-content: space-between;
  /* height: 100%; */
  gap:18px;
  /* background: #F7F7F7; */
  box-shadow: 0px 0px 8px rgba(31, 94, 250, 0.25);
  padding: 24px;
  border-radius: 16px;
  width: 317px;
  /* background: #F7F7F7; */
`

export const PartnerTitle = styled.p`
  font-family: Manrope;
  text-align: center;
  font-style: normal;
  font-weight: 300;
  font-size: 16px;
  margin:0px;
`
