import { Typography } from '@mui/material';
import React, { FC, useEffect, useState } from 'react';
import {
  Rectangle,
  InfoBlockItem,
  ValueField,
  ValueContainer,
  LabelTitle
} from './styles'

import {DefaultCopyField} from '../../components';
import { useTranslation } from 'react-i18next';
import {
  BodyItem,
  ItemImage,
  ItemText,
} from '../Home/exchange.styles';
import { ReactSVG } from "react-svg";
import handleImageUrl from '../../hooks/useImageUrl';
type OrderInfoProps = {
  width?:any,
  height?:any,
  order:Order,
}
type Order = {
  _id:string|number,
  from:any,
  to:any,
  email:string,
  amount:number|string,
  toExchange:number|string,
  wallet:number|string,
}
const LandscapeRectangle: FC<OrderInfoProps> = (props) => {
  const {
    width,
    height,
    order,
  } = props;

  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      setIsVisible(true);
    },500)
  }, []);
  const [t] = useTranslation()
  return (
    <>
      {isVisible && (
          <Rectangle width={width} height={height} >
            <Typography sx={{color:'#000', fontSize:"24px", fontWeight:'600'}} variant='h4'>{t("ORDER_INFO")}</Typography>
            <InfoBlockItem style={{flexDirection:'column'}}>
              {/* <LabelTitle>{t("ORDER_ID")}:</LabelTitle> */}
              <DefaultCopyField
                label={t("ORDER_ID")}
                value={order?._id}
              />
            </InfoBlockItem>
            <div style={{display:'flex', gap:'25px', flexDirection:'column', alignItems:"center", width:'100%'}}>
              <InfoBlockItem>
                <LabelTitle>{t("FROM")}:</LabelTitle>
                <ValueContainer>
                  <BodyItem style={{flexDirection:'row'}}>
                    {order.from.icon.indexOf('svg') !== -1 ?
                      <ReactSVG src={handleImageUrl(order.from.icon)} style={{width: '25px', height:'25px'}}  />
                    :
                      <ItemImage style={{}} src={handleImageUrl(order.from.icon)}/>
                    }
                    <ItemText>
                      {order.from.name||''}
                    </ItemText>
                  </BodyItem>
                  <ValueField>
                    {order.amount||''}
                  </ValueField>
                </ValueContainer>
              </InfoBlockItem>
              <InfoBlockItem>
                <LabelTitle>{t("TO")}:</LabelTitle>
                <ValueContainer>
                  <BodyItem style={{flexDirection:'row'}}>
                    {order.to.icon.indexOf('svg') !== -1 ?
                      <ReactSVG src={handleImageUrl(order.to.icon)} style={{width: '25px', height:'25px'}}  />
                    :
                      <ItemImage style={{}} src={handleImageUrl(order.to.icon)}/>
                    }
                    <ItemText>
                      {order.to.name||''}
                    </ItemText>
                  </BodyItem>
                  <ValueField>
                    {order.toExchange||''}
                  </ValueField>
                </ValueContainer>
              </InfoBlockItem>
            </div>
          </Rectangle>
      )}
    </>
  );
};

export default LandscapeRectangle;
