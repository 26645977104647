const devConfig = {
  apiUrl:'http://localhost:3001/',
  socketUrl:'http://localhost:3001/',
  // s3BucketUrl:process.env.NEXT_PUBLIC_AWS_S3_BUCKET_URL,
  s3BucketUrl:'https://exchage-assets.s3.eu-north-1.amazonaws.com/'
}
const prodConfig = {
  apiUrl:process.env.REACT_APP_API_URL,
  socketUrl:process.env.REACT_APP_SOCKET_URL,
  // s3BucketUrl:process.env.NEXT_PUBLIC_AWS_S3_BUCKET_URL,
  s3BucketUrl:'https://exchage-assets.s3.eu-north-1.amazonaws.com/'
}

export default process.env.NODE_ENV === 'production' ? prodConfig : prodConfig