import styled from 'styled-components'
import SVG from "react-inlinesvg";
type MessageProps = {
  isMe?:boolean,
}
type ChatTypeProps = {
  isAdmin?:boolean,
  isOpen?:boolean,
}
type EmojiProps = {
  chosenEmoji?:boolean
}
type CloseProp = {
  isEmoji?:boolean
}

export const DotImage = styled(SVG)`
  max-width: 10px;
  height: 10px;
`
export const Container = styled.div<ChatTypeProps>`
  width: ${({isAdmin}) => isAdmin ? '100%' : '383px'};
  height: ${({isAdmin}) => isAdmin ? 'calc(100vh - 94px)' : '530px'};
  transform:${({isOpen}) => isOpen ? 'scale(1)' : 'scale(0)'};
  opacity:${({isOpen}) => isOpen ? '1' : '0'};
  transform-origin: bottom right;
  transition-property: transform, opacity;
  transition-duration: 0.2s;
  transition-timing-function: ease-out;
  border-bottom-left-radius: 24px;
  border-bottom-right-radius: 24px;
  border-top-left-radius: 24px;
  border-top-right-radius: 24px;
  display: flex;
  flex-direction: column;
  position: fixed;
  box-sizing: border-box;
  background-color:transparent;
  right: 30px;
  bottom: 30px;
  z-index: 30;
  box-shadow: 12px 10px 10px 15px rgba(0, 0, 0, 0.1);

  @media screen and (max-width: 700px){
    z-index:20;
    right: 0px;
    left: 0px;
    bottom: 0px;
    width: 100%;
    /* margin:20px; */
    box-sizing: border-box;
  }
`

export const BodyChat = styled.div`
  height:650px;
  width:100%;
  display: flex;
  padding-left:20px;
  padding-right:20px;
  box-sizing: border-box;
  flex-direction: column;
  background: #F7F7F7;
  overflow-y: auto;
  ::-webkit-scrollbar {
    width: 8px;
  }
  ::-webkit-scrollbar-thumb {
    box-shadow: inset 0 0 2px 2px #DBDBDB;
    border-width: 0 2px 0 2px;
    border-color: rgba(28,28,28,0);
    border-style: solid;
    border-radius: 5px;
  }
  ::-webkit-scrollbar-track {
    margin: 12px 0 12px 0;
  }
  ::-webkit-scrollbar-button{
    display: none;
  }
  @media screen and (max-width: 700px){
    height:calc(100% - 94px);
  }
`

export const MessageWrapper = styled.div<MessageProps>`
  /* width:85%; */
  display: flex;
  box-sizing:border-box;
  /* margin:0px 12px 0px 12px; */
  padding:10px;
  align-items: center;
  justify-content: ${({isMe}) => isMe ? 'flex-end':'flex-start'};
  
`
export const ChatHeader = styled.div<ChatTypeProps>`
  /* width: ${({isAdmin}) => isAdmin ? '100%' : '483px'}; */
  height: 55px;
  padding: 15px 32px 15px 32px;
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  background: #1F5EFA;
  align-items: center;
  border-top-left-radius: 24px;
  border-top-right-radius: 24px;
  @media screen and (max-width: 700px){
    padding: 10px 16px 10px 16px;
    width: 100%;
    display: ${({isAdmin}) => isAdmin ? 'none' : 'flex'};
  }
`
export const CloseImage = styled.img<CloseProp>`
  width: 28px;
  height: 28px;
  @media screen and (max-width: 700px){
    width: 25px;
    height: 25px;
    /* bottom:15px; */
    display:${({isEmoji}) => isEmoji ? 'none' : ''};
  }
  &:hover{
    cursor: pointer;
  }

`

type MessageProp = {
  tailPosition?:any,
}


export const IconsWrapper = styled.div`
  display: flex;
  position: absolute;
  width: 85px;
  justify-content: space-between;
  bottom:28px;
  margin-right: 26px;
  z-index: 50px;
  gap:6px;
  @media screen and (max-width: 700px){
    width: 40px;
    bottom:28px;

  }
`

export const InputWrapper = styled.div`
  position: relative;
  width:100%;
  display: flex;
  box-sizing: border-box;
  justify-content: flex-end;
  background: #F7F7F7;
  padding:16px;
  border-bottom-left-radius: 24px;
  border-bottom-right-radius: 24px;
`
export const Emoji = styled.div<EmojiProps>`
  position: absolute;
  display: ${({chosenEmoji}) => chosenEmoji ? 'flex' : 'none'};
  right:50px;
  bottom:30px;

  &:hover{
    cursor: pointer;
  }

`

export const OldaMessagesGetBox = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  color:#000;
  margin-top:20px;
  &:hover{
    cursor:pointer;
  }
`

export const InputContainer = styled.input`
  padding-left: 16px;
  padding-right: 100px;
  /* padding-top: 10px; */
  font-family: Manrope;
  font-style: normal;
  box-sizing: border-box;
  font-weight: 400;
  font-size: 18px;
  line-height: 27px;
  display: flex;
  min-width:100%;
  max-width:100%;
  min-height: 59px;
  /* min-height: 59px; */
  background: rgba(187, 210, 255, 0.05);
  border:1.5px solid #6B6B6B;
  color:#000;
  border-radius: 24px;

  
  -moz-appearance: textfield; /* Firefox */
  ::-webkit-inner-spin-button,
  ::-webkit-outer-spin-button {
    display: none;
  }
  &:hover {
    background: rgba(187, 210, 255, 0.05);
    border: 1px solid #DBDBDB;
  }
  &::placeholder {
    /* color:rgba(0, 0, 0, 0.5); */
    /* font-family: Poppins; */
    font-style: normal;
    font-weight: 400;

  }
  &:focus-visible{
    /* background: #FFFFFF; */
    background: transparent;
    border: 1px solid #DBDBDB;
    
    border-radius: 24px;
    outline: none;
  }
  &:-webkit-autofill,
  &:-webkit-autofill:hover,
  &:-webkit-autofill:focus,
  &:-webkit-autofill:active {
      transition: background-color 5000s ease-in-out 0s;
      /* -webkit-text-fill-color: #fff !important; */
  }
  @media screen and (max-width: 700px){
    /* height: 30px; */
    box-sizing:border-box;
    flex: none;
    width:100%;
    font-size: 16px;
    padding-top: 0px;

  }
`
export const MessageImage = styled.img<MessageProp>`
  position: relative;
  /* padding: 10px; */
  /* box-sizing: border-box; */
  /* border-radius: ${({tailPosition}) => tailPosition === "left" ? "10px 10px 0 10px" : "10px 10px 10px 0"}; */
  background: #fff;
  object-fit:contain;
  /* word-wrap: break-word; */
  color: #000;
  /* border: 1px solid #87A9FF; */
  width:120px;
  &:hover{
    cursor: pointer;
  }
`
export const FileContainer = styled.div<MessageProp>`
  display: flex;
  flex-direction: column;
  gap:10px;
  position: relative;
  padding: 10px;
  box-sizing: border-box;
  overflow: hidden;
  border-radius: ${({tailPosition}) => tailPosition === "left" ? "10px 10px 0 10px" : "10px 10px 10px 0"};
  background: #fff;
  word-wrap: break-word;
  color: #000;
  border: 1px solid #87A9FF;
  max-width:250px;
  /* height:80px; */
  &:hover{
    cursor: pointer;
  }
`

export const ChatMessage = styled.div<MessageProp>`
  position: relative;
  max-width: 180px;
  /* padding: 10px; */
  box-sizing: border-box;
  /* border-radius: ${({tailPosition}) => tailPosition === "left" ? "10px 10px 0 10px" : "10px 10px 10px 0"}; */
  /* background: #fff; */
  word-wrap: break-word;
  color: #000;
  /* border: 1px solid #87A9FF; */
  /* border-radius: 24px 24px 24px 0px; */
  /* &::after {
    content: "";
    position: absolute;
    bottom: 0;
    ${({tailPosition}) => tailPosition === "left" ? "left: 100%;" : "right: 100%;"}
    display: block;
    width: 30px;
    height: 30px;
    background: radial-gradient(60px at top left, transparent 49%, #fff 51%) no-repeat ${({tailPosition}) => tailPosition} bottom;
    transform: ${({tailPosition}) => tailPosition === "left" ? "rotate(180deg)" : ""};
  } */
`