import styled from 'styled-components'
type InputProps = {
  isChat?:boolean,
  isDark?:boolean,
}
export const Container = styled.div`
  width:100%;
  display: flex;
  /* height:100%; */
  flex-direction: column;
  position: relative;
  /* @media screen and (max-width: 700px){
    height: 30px;
    flex: none;
    font-size: 12px;
  } */
`
export const InputContainerTextarea = styled.textarea<InputProps>`
  padding-left: 16px;
  padding-top:16px;
  padding-right: ${({isChat}) => isChat ? '130px' : '10px'};
  font-family: Manrope;
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 27px;
  /* max-width:100%; */
  color:#FFFF;
  max-height: 200px;
  min-height: 200px;
  background: rgba(187, 210, 255, 0.05);
  border: 1px solid #DBDBDB;
  border-radius: 5px;
  &:hover {
    background: rgba(187, 210, 255, 0.05);
    border: 1px solid #504e6b;
  }
  &:focus-visible{
    /* background: #FFFFFF; */

    background: transparent;
    border: 1px solid #504e6b;
    border-radius: 5px;
    outline: none;
  }
  &::placeholder {
    /* color:rgba(0, 0, 0, 0.5); */
    /* font-family: Poppins; */
    font-style: normal;
    font-weight: 400;
    color:#98adc0;
  }
`

export const InputContainer = styled.input<InputProps>`
  padding-left: 16px;
  padding-right: ${({isChat}) => isChat ? '100px' : '10px'};
  font-family: Manrope;
  font-size: 18px;
  line-height: 27px;
  display: flex;
  flex: 1 auto;
  box-sizing: border-box;
  /* width:100%; */
  height: 64px;
  /* background: rgba(187, 210, 255, 0.05); */
  border: 1px solid #888888;
  color:#FFFF;
  border-radius: 16px;
  background-color: transparent;

  -moz-appearance: textfield; /* Firefox */
  ::-webkit-inner-spin-button,
  ::-webkit-outer-spin-button {
    display: none;
  }
  &:hover {
    background: rgba(187, 210, 255, 0.05);
    border: ${({isChat}) => isChat ? '' : '1px solid #504e6b'};
  }
  &::placeholder {
    /* color:rgba(0, 0, 0, 0.5); */
    /* font-family: Poppins; */
    font-style: normal;
    font-weight: 400;
    color:#98adc0;
  }
  &:focus-visible{
    /* background: #FFFFFF; */
    background: transparent;
    border: ${({isChat}) => isChat ? '' : '1px solid #504e6b'};
    
    /* border-radius: ${({isChat}) => isChat ? '0px' : '5px'}; */
    outline: none;
  }
  &:-webkit-autofill,
  &:-webkit-autofill:hover,
  &:-webkit-autofill:focus,
  &:-webkit-autofill:active {
    color:${({isDark}) => isDark ? '#fff' : '#000'};
    transition: background-color 5000s ease-in-out 0s;
    -webkit-text-fill-color:${({isDark}) => isDark ? '#fff' : '#000'};
  }
  @media screen and (max-width: 700px){
    /* height: 30px; */
    flex: none;
    /* width:50%; */
    /* font-size: 12px; */
  }
`
export const ErrorText = styled.div`
  width:100%;
  display: flex;
  justify-content: flex-end;
  color:#F42F2F;
  font-family: Inter;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 17px;
  text-align: end;

`
export const Title = styled.label`
  position: absolute;
  background-color: #FFFF;
  margin:10px;
  top:-25px;
  left:4px;
  /* font-family: Inter; */
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  color:#2E2790;
  line-height: 27px;
  padding:2px;
`
export const SecurePasswordBox = styled.div`
  position: absolute;
  top:15px;
  right:24px;
  /* @media screen and (max-width: 700px){
    top:15px;
  } */
`