import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import en from './locales/en.json';
import ru from './locales/ru.json';

i18n.use(initReactI18next).init({
  resources: {
    ru: {
      translation: ru,
    },
    en: {
      translation: en,
    },
  },
  lng: localStorage.getItem('lang_bestchange') === 'ru' || localStorage.getItem('lang_bestchange') === 'en' ? 
  localStorage.getItem('lang_bestchange') : 'ru',
  keySeparator: false,
  interpolation: {
    escapeValue: false,
  },
  debug: true,
  react: {
    useSuspense: false
  },
});

export default i18n;
