import apiClient from './apiClient'
export type OrderProps = {
  // list: any[],
  // userinfo: object,
  // user: string,
  // totalPrice:number,
}
const reviewService = {
  getReviews:async(page:number) => apiClient.get(`review`),
  createReview:(data:any) => apiClient.post('review', data)
}
export default reviewService