import React, { FC, useEffect, useState } from "react";
import { StyledAccordion } from "../../../../components";
import {Container, FaqBox} from './styles'
import { Typography } from "@mui/material";
import { faqService } from "../../../../api";
import { useAuth } from "../../../../context/auth.context";


const Faq:FC<any> = () => {
  const [faq, setFaq] = useState<any[]>([])
  const {lang} = useAuth()
  useEffect(() => {
    faqService.getAllFaqList()
    .then((res) => {
      setFaq(res.data)
    })
    .catch((err) => {

    })
  },[])

  return (
    <Container id='faq'>
      <Typography sx={{fontSize:'64px'}} variant="h3">FAQ</Typography>
      {faq?.length == 0 || !faq ? null : faq.map((item:any) => (
        <StyledAccordion key={item[lang]?._id} item={item?.[lang]}>
          <Typography  variant="body1">
            {item[lang]?.message}
          </Typography>
        </StyledAccordion>
      ))}
    </Container>
  );
}

export default Faq;