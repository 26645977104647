import React, {FC, useEffect, useRef, useState} from 'react'
import {
  Container,
  CreateReviewButtonWrapper,
  ReviewsBox,
  ButtonWrapper,
  Slideshow,
  SlideshowSlider,
  SlideshowDots,
  SlideshowDot,
  MobileButton,
  WebButton,
} from './styles'
import CreateReviewComponent from './createReviewComponent';
import { Typography } from "@mui/material";
import { Button, ReviewCard } from '../../../../components';
import { useQueryParam, StringParam } from 'use-query-params';
import { useAuth } from '../../../../context/auth.context';
import { reviewService } from '../../../../api';
import { useLocalStorage } from '../../../../hooks/useLocalStorage';
import { useTranslation } from 'react-i18next';
import styled from "styled-components";
import Slider from 'react-slick';
type DotProps = {
  active:boolean
}

const StyledSlider = styled(Slider)`
  width: 100%;
  margin-bottom: 40px;
  padding: 0px;
  /* box-sizing: border-box; */
  gap:40px;
  /* height: 100vh; */

  .slick-dots li button{
    
    /* height: 25px;
    width: 25px;
    border-radius: 50%;
    cursor: pointer;
    margin: 15px 7px 0px; */
  }
`;


const ReviewsPage:FC = () => {
  const [reviews, setReviews] = useState<any[]>([])
  const [isOpenReviewForm, setIsOpenReviewForm] = useState<boolean>(false)
  const [loading, setLoading] = useState<boolean>(true)
  const [pageQuery, setPageQuery] = useQueryParam<any>('page', StringParam);
  const [arrCount, setArrCount] = useState<any[]>([])
  const [t] = useTranslation()
  const {
    getDataFromLs
  } = useLocalStorage()

  const getReviews = (page:number) => {
    reviewService.getReviews(page)
    .then((res) => {
      const localArr = getDataFromLs({key:'userReview'}) || []
      setReviews([...res.data, ...localArr])
    })
    .catch((err) => {
      console.log('error get Reviews', err);
    })
    .finally(() => {
      setLoading(false)
    })
  }
  useEffect(() => {
    getReviews(Number(pageQuery) || 1)
    const interval = setInterval(() => {
      getReviews(Number(pageQuery) || 1)
    },10000)
    return () => clearInterval(interval)
  },[])

  const delay = 10000;
  const [index, setIndex] = React.useState(0);
  const timeoutRef:any = React.useRef(null);

  function resetTimeout() {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }
  }

  React.useEffect(() => {
    resetTimeout();
    timeoutRef.current = setTimeout(
      () =>
        setIndex((prevIndex) =>
          prevIndex === reviews?.length - 1 ? 0 : prevIndex + 1
        ),
      delay
    );

    return () => {
      resetTimeout();
    };
  }, [index]);

  useEffect(() => {
    if(reviews?.length > 1){
      let arr = []
      for(let i = 0; i<=reviews.length/2; i++){
        arr.push('kek')
        setArrCount(arr)
      }
    }
  },[reviews])

  const [currentIndex, setCurrentIndex] = useState(0);


  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 2,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 10000,
    arrows: false,
    adaptiveHeight: true,
    // appendDots: (dots:any) => <ul>{dots}</ul>,
    customPaging: (i:any) => (
      <div onClick={() => {
        setIndex(i);
      }}>
        <SlideshowDot
          // key={idx}
          active={index === i}
          onClick={() => {
            setIndex(i);
          }}
        />
      </div>
    ),
    afterChange: (index:any) => {
      setCurrentIndex(index);
    },
    
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          // infinite: true,
          dots: true
        }
      },
      {
        breakpoint: 700,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 1,
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  };
  const sliderRef:any = useRef(null);

  useEffect(() => {
    const intervalId = setInterval(() => {
      if (sliderRef.current) {
        sliderRef.current.slickGoTo((currentIndex + 1) % Math.ceil(reviews.length / 4));
      }
    }, 10000);
    return () => clearInterval(intervalId);
  }, [currentIndex, reviews.length]);

  return (
    <ReviewsBox>
      <CreateReviewButtonWrapper>
        <Typography  variant='h3'>{t("REVIEWS")}</Typography>
        <WebButton>
          <Button 
            title={t("CREATE_REVIEW")}
            onClick={() => setIsOpenReviewForm(true)}
          />
        </WebButton>
      </CreateReviewButtonWrapper>
      <StyledSlider {...settings}>
        {reviews.map((item) => (
          <ReviewCard key={item.id} {...item}/>
        ))}
      </StyledSlider>
      {/* <SlideshowSlider
        style={{ transform: `translate3d(${-index * reviews.length*2}%, 0, 0)` }}
      >
        {reviews.map((item) => (
          <ReviewCard key={item.id} {...item}/>
        ))}
      </SlideshowSlider> */}
      {/* {reviews?.length === 1 ? null :
        <SlideshowDots>
          {arrCount?.map((_:any, idx:any) => (
            <SlideshowDot
              key={idx}
              active={index === idx}
              onClick={() => {
                setIndex(idx);
              }}
            />
          ))}
        </SlideshowDots>
      } */}
      <MobileButton>
        <Button 
          title={t("CREATE_REVIEW")}
          onClick={() => setIsOpenReviewForm(true)}
        />
      </MobileButton>
      {/* </StyledSlider> */}
        <CreateReviewComponent
          isOpen={isOpenReviewForm}
          handleClose={() => {
            getReviews(Number(pageQuery) || 1)
            setIsOpenReviewForm(false)
          }}
        />
    </ReviewsBox>
  )
}

export default ReviewsPage