import { Typography } from '@mui/material';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import {
  Container,
  TrustContainer,
  TrustWrapper,
  TitleWrapper,
  Icon,
  Description,
  Title,
  
} from './styles'
import ComfIc from './comf-ic.svg'
import FastIc from './fast-ic.svg'
import ProfIc from './prof-ic.svg'

const TrustBlock:React.FC = () => {
  const [t] = useTranslation()

  const data = [
    {
      icon:FastIc,
      title:t("FAST"),
      desc:t("FAST_DESC"),
      id:'1',
    },
    {
      icon:ComfIc,
      title:t("COMFORTABLE"),
      desc:t("COMFORTABLE_DESC"),
      id:'2',
    },
    {
      icon:ProfIc,
      title:t("PROFITABLE"),
      desc:t("PROFITABLE_DESC"),
      id:'3',
    }
  ]
  return(
    <Container>
      <Typography sx={{fontSize:'64px'}} variant="h3">{t("TRUSTED_SINCE_TITLE")}</Typography>
      <TrustWrapper>
        {data.map((item, ind) => (
          <TrustContainer ind={ind+1} key={item.id}>
            <TitleWrapper>
              <Icon src={item.icon}/>
              <Title>{item.title}</Title>
            </TitleWrapper>
            <Description>{item.desc}</Description>
          </TrustContainer>
        ))}

      </TrustWrapper>
    </Container>
  )
}


export default TrustBlock