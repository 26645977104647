import axios from "axios";
import Config from "../Config";
import { MessagesKit } from '../Kits';
// import { get } from 'lodash';
console.log('window', window.location);
const ApiClient = axios.create({
  baseURL:Config.apiUrl,
  // baseURL: 'http://192.168.31.235:3001/',
  headers: {
    // 'Content-Type': 'application/json'
    // Authorization: `Bearer ${localStorage.getItem('authToken')}` || ''
  }
});

const formatError = (err:any) => {
  // const data = get(err, 'response.data', null);
  // if (data) {
  //   if (typeof data === 'object') {
  //     return Object.keys(data)
  //       .map((key) => (typeof data[key] === 'string' ? data[key] : JSON.stringify(data[key]).replace(/[^a-zA-Z ]/g, '')))
  //       .join(' ');
  //   }
  //   return data;
  // }
  // return MessagesKit.global.errorOccured;
};

export { formatError };

// ApiClient.interceptors.request.use((config) => {
// console.log({
//   url: config?.url,
//   headers: config?.headers
// });
// console.log(config);
// return config;
// })

// ApiClient.interceptors.response.use(
//   (response) => Promise.resolve(response),
//   (error) => Promise.reject(formatError(error)),
// );

export const setClientToken = (token:any) => {
  if (token) {
    ApiClient.defaults.headers.common.Authorization = `Bearer ${token}`;
  }
};

export const resetClientToken = () => {
  delete ApiClient.defaults.headers.common.Authorization;
};

export default ApiClient