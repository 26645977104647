import apiClient from './apiClient'
export type FaqProps = {
  title?:string,
  message?:string,
  image?: string,
  _id?:string,
}
const faqService = {
  getAllFaqList: async () =>  apiClient.get('faq'),
  // addFaq:(data:FaqProps) => apiClient.post('faq', data),
  // updateFaq:(data:FaqProps) => apiClient.patch(`faq/${data._id}`, data),
  // deleteFaq:(id:string) => apiClient.delete(`faq/${id}`),
}
export default faqService